import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROJECTS,
  GET_PROJECT_BY_ID,
  GET_PROJECT_STUDIO_SESSIONS,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  CREATE_PROJECT,
  UPLOAD_PROJECT_LOGO,
  GET_PROJECT_SECTIONS,
  GET_PROJECT_STATUSES,
  GET_PROJECT_LAYOUT,
  PROJECT_AUTOFILL,
} from "constants/projects";

import * as Api from "api";

export const getProjects = () => async (dispatch) =>
  networkAction(dispatch, GET_PROJECTS, Api.getProjects, []);

export const getProjectLayout = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_LAYOUT, Api.getProjectLayout, [id]);

export const getProjectSections = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_SECTIONS, Api.getProjectSections, [id]);

export const projectAutofill = (id) => async (dispatch) =>
  networkAction(dispatch, PROJECT_AUTOFILL, Api.projectAutofill, [id]);

export const getProjectStatuses = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_STATUSES, Api.getProjectStatuses, [id]);

export const getProjectById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_BY_ID, Api.getProjectById, [id]);

export const getProjectStudioSessions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROJECT_STUDIO_SESSIONS,
    Api.getProjectStudioSessions,
    [id]
  );

export const deleteProject = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROJECT, Api.deleteProject, [id]);

export const createProject = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROJECT, Api.createProject, [body]);

export const updateProject = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROJECT, Api.updateProject, [id, body]);

export const uploadProjectLogo = (projectID, logoImage) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_PROJECT_LOGO, Api.uploadProjectLogo, [
    projectID,
    logoImage,
  ]);
