import {
  Chip,
  CircularProgress,
  Grid,
  Hidden,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import * as Actions from "actions";
import FormMusician from "hooks/FormMusician";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function MusicianSuggestions({
  projectID,
  sectionMusician,
  onClose,
  setShowList,
  showList,
}) {
  const [suggestions, setSuggestions] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [musicianAddEl, setMusicianAddEl] = React.useState(false);
  const [importFromGoogle, setImportFromGoogle] = React.useState(false);
  const [selectedMusician, setSelectedMusician] = React.useState();
  const [formMusicianOpen, setFormMusicianOpen] = React.useState(false);
  const addMusicianRef = React.useRef();
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];
  const musicians = useSelector((state) => state.musicians);

  React.useEffect(() => {
    if (sectionMusician?.id) {
      setLoading(true);
      dispatch(
        Actions.getInstrumentSectionProjectMusicians(
          sectionMusician.sectionID,
          projectID
        )
      ).then((r) => {
        setSuggestions(r.payload);
        setLoading(false);
      });
    }

    if (showList && !sectionMusician) {
      showAllMusicians();
    }
  }, [sectionMusician, showList]);

  const refreshSuggestions = () => {
    setLoading(true);
    dispatch(
      Actions.getInstrumentSectionProjectMusicians(
        sectionMusician.sectionID,
        projectID
      )
    ).then((r) => {
      setSuggestions(r.payload);
      setLoading(false);
    });
  };

  let elems = suggestions;
  if (showAll && musicians?.length > 0) elems = musicians;

  let list = elems
    ? elems.sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      })
    : [];

  const linkMusician = (musicianID) => {
    dispatch(
      Actions.updateProjectAlternate(sectionMusician.id, {
        musicianID,
      })
    ).then(() => {
      dispatch(Actions.getProjectSections(projectID));
    });
  };

  const showAllMusicians = () => {
    setLoading(true);
    setShowAll(true);
    dispatch(Actions.getMusicians()).then(() => setLoading(false));
  };

  const refresh = () => {
    if (showAll) showAllMusicians();
    else refreshSuggestions();
  };

  const deletePosition = () => {
    dispatch(Actions.deleteProjectAlternate(sectionMusician.id)).then(() => {
      onClose();
      dispatch(Actions.getProjectSections(projectID));
    });
  };

  if (searchText) {
    list = list.filter(
      (a) =>
        `${a.firstName} ${a.nickName} ${a.lastName} ${a.instruments
          .reduce((a, i) => {
            a.push(i.name);
            return a;
          }, [])
          ?.join(" ")}`
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
    );
  }

  return (
    <div>
      <Grid
        container
        justify="space-between"
        spacing={1}
        alignItems="center"
        style={{ maxWidth: 600, margin: "auto" }}
      >
        <Grid style={{ lineHeight: 0 }}>
          <Typography variant="h6" style={{ lineHeight: 1 }}>
            {showList && !sectionMusician
              ? "Musician Database"
              : "Select Musician"}
          </Typography>
          <Hidden xsDown>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginTop: -4, fontSize: 11 }}
            >
              <i class="fas fa-hand-paper"></i> This list is private, belongs to
              you and won't be shared.
            </Typography>
          </Hidden>
        </Grid>
        <Grid item>
          <Chip
            size="small"
            style={{ marginRight: 4 }}
            label="+ Musician"
            style={{ background: primaryColor, color: "white", marginRight: 4 }}
            onClick={(e) => {
              setFormMusicianOpen(true);
            }}
          />
          {showList && !sectionMusician ? (
            []
          ) : (
            <Chip
              variant="outlined"
              label={!showAll ? "Show All" : "Suggestions"}
              size="small"
              onClick={() => {
                if (!showAll) {
                  showAllMusicians();
                } else {
                  setShowAll(false);
                }
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            position: "sticky",
            top: 0,
          }}
        >
          <InputBase
            style={{
              background: "rgba(155,155,155,0.1)",
              borderRadius: 40,
              padding: "0px 8px",
              width: "calc(100% - 16px)",
            }}
            onChange={(e) => setSearchText(e.target.value)}
            fullWidth
            autoFocus
            startAdornment={
              <Search style={{ color: "rgba(155,155,155,0.8)" }} />
            }
            placeholder="Search..."
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            height:
              showList && !sectionMusician
                ? "calc(calc(var(--vh, 1vh) * 100) - 110px)"
                : "40vh",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
              <br />
              <Typography variant="caption" color="textSecondary">
                Loading...
              </Typography>
            </div>
          ) : (
            <List dense style={{ padding: 0, position: "relative" }}>
              {list &&
                list.map((m, i) => {
                  const JSX = [];
                  if (Number(i) === 0) {
                    JSX.push(
                      <Typography
                        variant="caption"
                        style={{
                          background: "#e0e0e0",
                          display: "block",
                          position: "sticky",
                          top: -4,
                          zIndex: 99,
                          padding: "0px 10px",
                          width: "calc(100% - 20px)",
                        }}
                      >
                        {m.lastName[0]?.toUpperCase()}
                      </Typography>
                    );
                  } else if (Number(i) < list.length - 1) {
                    const prev = list[Number(i - 1)];
                    if (
                      prev.lastName[0]?.toUpperCase() !==
                      m.lastName[0]?.toUpperCase()
                    ) {
                      JSX.push(
                        <Typography
                          variant="caption"
                          style={{
                            background: "#e0e0e0",
                            display: "block",
                            position: "sticky",
                            top: -4,
                            zIndex: 999,
                            padding: "0px 10px",
                            width: "calc(100% - 20px)",
                          }}
                        >
                          {m.lastName[0]?.toUpperCase()}
                        </Typography>
                      );
                    }
                  }

                  JSX.push(
                    <ListItem
                      onClick={
                        sectionMusician
                          ? () => linkMusician(m.id)
                          : (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectedMusician(m);
                              setFormMusicianOpen(true);
                            }
                      }
                      key={`musicians_${m.id}`}
                      divider
                      dense
                      style={{ padding: 0 }}
                      button
                    >
                      <ListItemText
                        primary={
                          <Typography style={{ maxWidth: "calc(100% - 30px)" }}>
                            {m.nickName || `${m.firstName} ${m.lastName}`}{" "}
                            <Typography variant="caption" color="textSecondary">
                              {m.instruments
                                .reduce((a, i) => {
                                  a.push(i.name);
                                  return a;
                                }, [])
                                ?.join(", ")}
                            </Typography>
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setSelectedMusician(m);
                            setFormMusicianOpen(true);
                          }}
                        >
                          <Icon className="fad fa-pencil" fontSize="inherit" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );

                  return JSX;
                })}
              {list.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <Typography color="textSecondary">No match found</Typography>
                </div>
              ) : (
                []
              )}
            </List>
          )}
          <div
            style={{
              position: "sticky",
              bottom: 0,
              background: "white",
              zIndex: 1000,
            }}
          >
            <Grid container spacing={1} justify="space-between">
              {/* <Grid item>
                <Chip
                  size="small"
                  onClick={deletePosition}
                  style={{ marginRight: 4 }}
                  label={
                    <span>
                      <i class="fad fa-trash-alt p-right"></i>Delete Position
                    </span>
                  }
                />
              </Grid>
              <Grid item>
                <Chip
                  size="small"
                  label="+ Musician"
                  onClick={() => setFormMusicianOpen(true)}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                style={{ display: "flex", background: "white" }}
              >
                {/* <Chip
                  onClick={() => {
                    setShowList(false);
                    onClose();
                    setShowAll(false);
                  }}
                  style={{ flex: 1, marginRight: 4 }}
                  label="Close"
                /> */}
                <Chip
                  onClick={() => {
                    setShowList(false);
                    setShowAll(false);
                  }}
                  style={{ flex: 1, marginLeft: 4 }}
                  label={
                    showList && !sectionMusician
                      ? "Close"
                      : "Return to Position Details"
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <FormMusician
        selectedMusician={selectedMusician}
        refresh={refresh}
        open={formMusicianOpen}
        onClose={() => {
          setFormMusicianOpen(false);
          setSelectedMusician(null);
        }}
      />
    </div>
  );
}
