// import PropTypes from 'prop-types';
// material-ui
import { Chip, Paper, Typography, useTheme } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import * as Actions from "actions";
import MusicianSuggestions from "hooks/MusicianSuggestions";
import SectionMusicianPanel from "hooks/SectionMusicianPanel";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function Order({ order, alt }) {
  const theme = useTheme();
  let primaryColor = "#D7B740";
  if (alt) primaryColor = "#607d8b";
  return (
    <div
      style={{
        background: primaryColor,
        marginRight: 4,
        color: "white",
        padding: "2px 4px",
        borderRadius: 20,
        height: 12,
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontSize: 11,
          fontWeight: 600,
        }}
      >
        {order + 1}
      </span>
      <i
        class={alt ? "fad fa-repeat-alt" : "fas fa-star"}
        style={{
          fontSize: 9,
          paddingLeft: 4,
        }}
      ></i>
    </div>
  );
}

export default function Layout({ sections = [], projectID }) {
  const classes = useStyles(styles);
  const [sectionMusician, setSectionMusician] = React.useState();
  const [panelHeight, setPanelHeight] = React.useState(0);
  const [expandAll, setExpandAll] = React.useState(true);
  const [showList, setShowList] = React.useState(false);
  const sectionRoles = useSelector((state) => state.sectionRoles);
  const dispatch = useDispatch();
  const theme = useTheme();
  const panel = React.useRef();
  const families = [];
  const allNodes = [];

  React.useEffect(() => {
    if (sectionRoles.length === 0) {
      dispatch(Actions.getSectionRoles());
    }
    if (sectionMusician?.id) {
      dispatch(Actions.getProjectAlternateById(sectionMusician.id)).then(
        (r) => {
          setSectionMusician(r.payload);
        }
      );
    }
  }, [sectionRoles, sections]);

  const primaryColor = theme.palette.primary.color;

  const setSList = (v) => {
    setShowList(v);
  };

  const selectMusician = (e) => {
    setSectionMusician(e);
    setTimeout(() => {
      setPanelHeight(panel.current?.clientHeight);
    }, 200);
  };

  const clearMusician = () => {
    setSectionMusician(null);
    setTimeout(() => setPanelHeight(0), 200);
  };

  for (const key in sections) {
    if (Object.hasOwnProperty.call(sections, key)) {
      const section = sections[key];
      allNodes.push(`s${section.id}`);
      const index = families.findIndex((f) => f.id === section.familyID);
      if (index !== -1) {
        families[index].sections.push(section);
      } else {
        allNodes.push(`f${section.familyID}`);
        families.push({
          name: section.familyName,
          id: section.familyID,
          sections: [section],
        });
      }
    }
  }

  return (
    <div>
      <div
        style={{
          paddingBottom: panelHeight,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Chip
            label={
              <span>
                <i class="fad fa-user-music p-right"></i>Musician Database
              </span>
            }
            onClick={() => {
              setSectionMusician(null);
              setShowList(true);
            }}
            size="small"
          />
        </div>
        <div style={{ position: "relative" }}>
          <Typography
            style={{
              position: "absolute",
              right: 20,
              zIndex: 99,
              cursor: "pointer",
            }}
            variant="caption"
            color="textSecondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setExpandAll(!expandAll);
            }}
          >
            {!expandAll ? "Expand All" : "Collapse All"}
          </Typography>
          <TreeView
            key={expandAll}
            className={classes.root}
            defaultExpanded={expandAll ? allNodes : undefined}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {families.map((f, i) => (
              <TreeItem
                nodeId={`f${f.id}`}
                label={<Typography variant="h6">{f.name}</Typography>}
              >
                {f.sections?.map((s, j) => (
                  <TreeItem
                    nodeId={`s${s.id}`}
                    label={
                      <Typography variant="subtitle1">{s.name}</Typography>
                    }
                  >
                    {s.projectMusicians.map((m) => (
                      <TreeItem
                        nodeId={`m${m.id}`}
                        style={{
                          margin: "6px 0px",
                          background:
                            m.id === sectionMusician?.id
                              ? primaryColor[50]
                              : undefined,
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") selectMusician(m);
                        }}
                        onClick={() => selectMusician(m)}
                        label={
                          m.musicianID > 0 ? (
                            <div
                              className="flex centerV"
                              style={{
                                zIndex:
                                  sectionMusician?.id === m.id
                                    ? 999999
                                    : undefined,
                              }}
                            >
                              <Order alt={!m.prime} order={m.sectionOrder} />
                              <Typography
                                variant="caption"
                                style={{
                                  color: m.prime ? "black" : "#607d8b",
                                }}
                              >
                                <b>
                                  {m.musician.firstName} {m.musician.lastName}
                                </b>
                                {` - ${
                                  sectionRoles.find(
                                    (sr) => sr.id === m.sectionRoleID
                                  )?.name ?? ""
                                }`}
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontSize: 10,
                                    opacity: 0.3,
                                    color: "black",
                                  }}
                                >
                                  {m.musician.email && (
                                    <i class="fas fa-envelope p-left"></i>
                                  )}
                                  {m.musician.phone && (
                                    <i class="fas fa-phone p-left"></i>
                                  )}
                                  {m.notes && (
                                    <i class="fad fa-sticky-note p-left"></i>
                                  )}
                                </Typography>
                              </Typography>
                            </div>
                          ) : (
                            <div className="flex centerV">
                              <Order order={m.sectionOrder} alt={!m.prime} />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{
                                  border: "solid 1px rgba(155,155,155,0.3)",
                                  background: "rgba(155,155,155,0.1)",
                                  padding: "0px 4px",
                                  borderRadius: 10,
                                  marginRight: 4,
                                }}
                              >
                                <i class="fad fa-chair"></i> Empty Position
                              </Typography>
                              <Typography variant="caption">
                                {` - ${
                                  sectionRoles.find(
                                    (sr) => sr.id === m.sectionRoleID
                                  )?.name ?? ""
                                }`}
                              </Typography>
                              <Typography
                                variant="caption"
                                style={{
                                  fontSize: 10,
                                  opacity: 0.3,
                                  color: "black",
                                }}
                              >
                                {m.notes && (
                                  <i class="fad fa-sticky-note p-left"></i>
                                )}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </div>
      </div>
      {sectionMusician || showList ? (
        <>
          <Slide in direction="up">
            <Paper
              ref={panel}
              elevation={24}
              style={{
                position: "fixed",
                bottom: 0,
                width: "calc(100vw - 40px)",
                zIndex: 1300,
                left: 0,
                padding: 20,
              }}
            >
              {!showList ? (
                <SectionMusicianPanel
                  projectID={projectID}
                  setShowList={setSList}
                  key={sectionMusician?.id}
                  sectionMusician={sectionMusician}
                  onClose={() => clearMusician()}
                />
              ) : (
                <MusicianSuggestions
                  projectID={projectID}
                  setShowList={setSList}
                  showList={showList}
                  sectionMusician={sectionMusician}
                  onClose={() => clearMusician()}
                />
              )}
            </Paper>
          </Slide>
        </>
      ) : (
        []
      )}
    </div>
  );
}
