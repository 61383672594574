export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_PROJECT_STUDIO_SESSIONS = "GET_PROJECT_STUDIO_SESSIONS";
export const UPLOAD_PROJECT_LOGO = "UPLOAD_PROJECT_LOGO";
export const GET_PROJECT_SECTIONS = "GET_PROJECT_SECTIONS";
export const GET_PROJECT_LAYOUT = "GET_PROJECT_LAYOUT";
export const GET_PROJECT_STATUSES = "GET_PROJECT_STATUSES";
export const PROJECT_AUTOFILL = "PROJECT_AUTOFILL";
