const { _APP_CONFIG } = window;

// api
export const REACT_APP_API = _APP_CONFIG.api.endpoint;
export const REACT_APP_API_PREFIX = _APP_CONFIG.api.routePrefix;

// theme
export const REACT_APP_PRIMARY_COLOR = _APP_CONFIG.theme.primaryColor;
export const REACT_APP_SECONDARY_COLOR = _APP_CONFIG.theme.secondaryColor;

// app
export const REACT_APP_LOGO = _APP_CONFIG.app.logo;

// centrifugo
export const CENTRIFUGO_URL = _APP_CONFIG.centrifugo.url;
export const CENTRIFUGO_TOKEN = _APP_CONFIG.centrifugo.token;

// Google
export const GOOGLE_MAPS_GEOCODING_KEY = _APP_CONFIG.googleMaps.geocoding;
export const GOOGLE_MAPS_TIMEZONE_KEY = _APP_CONFIG.googleMaps.timezone;
