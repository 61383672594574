// import PropTypes from 'prop-types';
// material-ui
import { Button, TextField, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
import queryString from "query-string";
import React from "react";
import { useDispatch } from "react-redux";
import MaskedInput from "react-text-mask";

function ReferenceMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        /\d|[a-z]/,
        /\d|[a-z]/,
        /\d|[a-z]/,
        "-",
        /\d|[a-z]/,
        /\d|[a-z]/,
        /\d|[a-z]/,
        /\d|[a-z]/,
        "-",
        /\d|[a-z]/,
        /\d|[a-z]/,
        /\d|[a-z]/,
      ]}
      placeholderChar={"_"}
      showMask
    />
  );
}

export default function QuoteIdentification({ onSubmit }) {
  const urlParams = getJsonFromUrl(window.location);
  const [quoteReference, setQuoteReference] = React.useState(
    urlParams.reference
  );
  const [error, setError] = React.useState("");
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];
  const dispatch = useDispatch();

  const checkReference = () => {
    const parsed = queryString.parse(window.location.search);
    parsed.reference = quoteReference;
    const stringified = queryString.stringify(parsed);
    window.location.search = stringified;
    dispatch(
      Actions.getQuotes([
        { name: "quotes.reference", comparison: "eq", value: quoteReference },
      ])
    ).then((r) => {
      if (r.payload.length !== 1) {
        setError("Quote not found");
      }
    });
  };

  const responseCallback = (response) => {
    console.log(response);
  };

  return (
    <div className="flex centerH centerV">
      <div className="flex column centerV">
        <div style={{ textAlign: "center" }}>
          <img
            src="https://storage.googleapis.com/rhapsody/logos/rhapsody-concierge.png"
            alt="rhapsody-logo"
            style={{ width: "80%", maxWidth: 300 }}
          />
        </div>
        <TextField
          style={{ marginTop: 20, minWidth: 300 }}
          variant="outlined"
          color="primary"
          label="Quote Reference"
          InputProps={{
            inputComponent: ReferenceMask,
            style: { color: primaryColor },
            autoCapitalize: "off",
          }}
          value={quoteReference}
          onChange={(e) => setQuoteReference(e.target.value)}
          placeholder="XXX-XXX-XXX"
        />
        {error ? (
          <Alert severity="error" style={{ margin: " 8px 0px", minWidth: 270 }}>
            {error}
          </Alert>
        ) : (
          []
        )}
        <br />
        <Button
          onClick={checkReference}
          variant="contained"
          style={{ minWidth: 300, background: primaryColor, color: "white" }}
        >
          Let's Go!
        </Button>
      </div>
    </div>
  );
}
