export default (theme) => ({
  container: {
    width: "100vw",
    height: "calc(var(--vh, 1vh) * 100)",
    background: "rgba(255,255,255,0.5)",
    position: "fixed",
    top: 0,
    backdropFilter: "blur(30px)",
    WebkitBackdropFilter: "blur(30px)",
    zIndex: 99999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  method: {
    flex: 1,
    margin: 8,
    padding: "30px 0px",
    textAlign: "center",
    background: "rgba(255,255,255,0.9)",
    fontSize: 50,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 8,
    cursor: "pointer",
  },
});
