export default (theme) => ({
  container: {
    padding: 5,
  },
  fab: {
    color: "black",
  },
  staticTooltip: {
    color: "white",
    background: "#424242",
    fontSize: 12,
  },
});
