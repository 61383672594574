import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const workSessions = `/${service}/workSessions`;

export function getStudioSessions(filters) {
  const url = `${workSessions}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function processBazaar() {
  const url = `${workSessions}/processBazaar`;
  return {
    method: GET,
    url,
  };
}

export function calculatePensionWelfare(id, body) {
  const url = `${workSessions}/${id}/calculatePensionWelfare`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function processMetadata(id) {
  const url = `${workSessions}/${id}/processMetadata`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionById(id) {
  const url = `${workSessions}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionMercury(id) {
  const url = `${workSessions}/${id}/mercury`;
  return {
    method: GET,
    url,
  };
}

export function studioSessionAutofill(id) {
  const url = `${workSessions}/${id}/autofill`;
  return {
    method: GET,
    url,
  };
}

export function duplicateSession(id) {
  const url = `${workSessions}/${id}/duplicate`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionStatuses(id) {
  const url = `${workSessions}/${id}/statuses`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionDashboard(id) {
  const url = `${workSessions}/${id}/dashboard`;
  return {
    method: GET,
    url,
  };
}

export function notify(id, body) {
  const url = `${workSessions}/${id}/notify`;
  return {
    method: PUT,
    body,
    url,
  };
}

export function notifyPreview(id, body) {
  const url = `${workSessions}/${id}/notifyHTML`;
  return {
    method: PUT,
    body,
    url,
  };
}

export function getStudioSessionInvites(token) {
  const url = `${workSessions}/invites?token=${token}`;
  return {
    method: GET,
    url,
  };
}

export function acknowledgeStudioSessionInvite(id, body) {
  const url = `${workSessions}/${id}/acknowledge`;
  return {
    method: PUT,
    body,
    url,
  };
}

export function getStudioSessionTunes(id) {
  const url = `${workSessions}/${id}/tunes`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionEngagements(id) {
  const url = `${workSessions}/${id}/engagements`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionIdentification(id) {
  const url = `${workSessions}/${id}/identifications`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionEvents(id) {
  const url = `${workSessions}/${id}/events`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionRehearsals(id) {
  const url = `${workSessions}/${id}/rehearsals`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionMusicians(id) {
  const url = `${workSessions}/${id}/musicians`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionSections(id) {
  const url = `${workSessions}/${id}/sections`;
  return {
    method: GET,
    url,
  };
}

export function updateStudioSession(id, body) {
  const url = `${workSessions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function createStudioSessionCategory(id, body) {
  const url = `${workSessions}/${id}/categories`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStudioSessionCategory(id, body) {
  const url = `${workSessions}/${id}/categories`;
  return {
    method: DELETE,
    url,
    body,
  };
}

export function deleteStudioSession(id) {
  const url = `${workSessions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStudioSession(body) {
  const url = `${workSessions}`;
  return {
    method: POST,
    url,
    body,
  };
}
