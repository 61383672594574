// material-ui
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import Centrifugo from "components/Centrifugo";
import { getJsonFromUrl } from "helpers";
import Intake from "hooks/Intake";
import PasswordFreeSignin from "hooks/PasswordFreeSignin";
import QuoteIdentification from "hooks/QuoteIdentification";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  quote: state.quote,
});

const mapDispatchToProps = (dispatch) => ({
  createProject: (...args) => dispatch(Actions.createProject(...args)),
  updateQuote: (...args) => dispatch(Actions.updateQuote(...args)),
  getQuotes: (...args) => dispatch(Actions.getQuotes(...args)),
  getQuoteByRef: (...args) => dispatch(Actions.getQuoteByRef(...args)),
  getStudios: (...args) => dispatch(Actions.getStudios(...args)),
  getSectionRoles: (...args) => dispatch(Actions.getSectionRoles(...args)),
  requestPLCode: (...args) => dispatch(Actions.requestPLCode(...args)),
  signout: (...args) => dispatch(Actions.signout(...args)),
  getSessionTypes: (...args) => dispatch(Actions.getSessionTypes(...args)),
});

class Root extends Component {
  static propTypes = {
    getStudioSessionMercury: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      reference: urlParams.reference,
      auth: urlParams.auth,
      passwordFreeOpen: false,
    };

    if (urlParams.reference) {
      this.retrieveQuote(urlParams.reference);
    }
    this.refreshInnerHeight();
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  init() {
    const { getStudios, getSectionRoles, getSessionTypes } = this.props;
    getStudios();
    getSectionRoles();
    getSessionTypes();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quote.projectID === null) {
      nextProps.createProject({ concierge: true }).then((r) => {
        if (r.success) {
          const project = r.payload;
          nextProps
            .updateQuote(nextProps.quote.id, { projectID: project.id })
            .then(() => {
              this.retrieveQuote(nextProps.quote.reference);
            });
        }
      });
    }
  }

  retrieveQuote(reference) {
    const { getQuoteByRef, requestPLCode, signout } = this.props;
    getQuoteByRef(reference).then((r) => {
      if (r.status === 401) {
        this.setState({ passwordFreeOpen: true });
        requestPLCode({ reference });
      }
      if (r.status === 404) {
        signout().then(() => this.retrieveQuote(reference));
      }
      if (r.success) this.init();
    });
  }

  refreshInnerHeight() {
    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  render() {
    const { reference, auth } = this.state;
    const { quote, getQuoteByRef } = this.props;
    let JSX = <Intake projectID={quote?.projectID} />;

    if (!quote || quote?.projectID === null) JSX = <QuoteIdentification />;

    if (reference && !quote) {
      JSX = (
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "calc(var(--vh, 1vh) * 100)",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <Centrifugo>
        <div className="root flex">{JSX}</div>
        <PasswordFreeSignin
          open={this.state.passwordFreeOpen}
          onAuthenticated={() => {
            this.setState({ passwordFreeOpen: false });
            this.retrieveQuote(reference);
          }}
          onClose={() => this.setState({ passwordFreeOpen: false })}
        />
      </Centrifugo>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
