import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import googleContacts from "./googleContacts";
import instrumentFamilies from "./instrumentFamilies";
import instruments from "./instruments";
import instrumentSections from "./instrumentSections";
import musicians from "./musicians";
import project from "./project";
import projectSections from "./projectSections";
import quote from "./quote";
import sectionRoles from "./sectionRoles";
import sessionTypes from "./sessionTypes";
import studios from "./studios";
import user from "./user";
import workSessions from "./workSessions";
export default combineReducers({
  user,
  app,
  instrumentFamilies,
  instrumentSections,
  project,
  projectSections,
  sectionRoles,
  instruments,
  musicians,
  quote,
  workSessions,
  studios,
  sessionTypes,
  googleContacts,
});
