import {
  Checkbox,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Search from "@material-ui/icons/Search";
import React from "react";
import { useSelector } from "react-redux";

export default function InstrumentSelect({
  open,
  onClose,
  selected,
  onChange,
  onAdd,
  onDelete,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const instruments = useSelector((state) => state.instruments);
  const [searchText, setSearchText] = React.useState("");
  const primaryColor = theme.palette.primary.color[600];

  const handleToggle = (value) => () => {
    const currentIndex = selected.findIndex((s) => s.id === value.id);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      onAdd([value]);
      newSelected.push(value);
    } else {
      onDelete([value]);
      newSelected.splice(currentIndex, 1);
    }

    onChange(newSelected);
  };

  let list = instruments.reduce((a, i) => {
    a.push({ id: i.id, name: i.name, icon: i.icon });
    return a;
  }, []);
  if (searchText) {
    list = instruments.filter(
      (a) => a.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
  }

  const close = () => {
    onClose();
    setSearchText("");
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            alignItems: "center",
          }}
        >
          <Typography variant="h6">What instruments?</Typography>
          <Typography
            variant="caption"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onDelete(selected);
              onChange([]);
            }}
          >
            Deselect All
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <List style={{ minHeight: "75vh", minWidth: 400 }}>
          <div
            style={{
              position: "sticky",
              zIndex: 999,
              top: -8,
              background: "white",
            }}
          >
            <InputBase
              style={{
                background: "rgba(155,155,155,0.1)",
                borderRadius: 40,
                padding: "0px 8px",
                width: "100%",
              }}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              fullWidth
              autoFocus
              startAdornment={
                <Search style={{ color: "rgba(155,155,155,0.8)" }} />
              }
              placeholder="Search..."
            />
          </div>
          {list.map((i) => (
            <ListItem dense button onClick={handleToggle(i)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.findIndex((s) => s.id === i.id) !== -1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={i.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ background: primaryColor, color: "white" }}
          variant="contained"
          fullWidth
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
