import { GET_SESSION_TYPES } from "constants/sessionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case `${GET_SESSION_TYPES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
