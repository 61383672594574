import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const quotes = `${REACT_APP_API_PREFIX}/${service}/quotes`;

export function getQuotes(filters) {
  const url = `${quotes}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function requestPLCode(body) {
  const url = `${quotes}/plCode`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function getQuoteById(id) {
  const url = `${quotes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getQuoteByRef(ref) {
  const url = `${quotes}/byRef/${ref}`;
  return {
    method: GET,
    url,
  };
}

export function updateQuote(id, body) {
  const url = `${quotes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteQuote(id) {
  const url = `${quotes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createQuote(body) {
  const url = `${quotes}`;
  return {
    method: POST,
    url,
    body,
  };
}
