// import PropTypes from 'prop-types';
// material-ui
import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import RichText from "hooks/RichText";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Project({ projectID }) {
  const [name, setName] = React.useState("");
  const [composer, setComposer] = React.useState("");
  const [contractor, setContractor] = React.useState("");
  const [agreement, setAgreement] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!project && projectID) {
      dispatch(Actions.getProjectById(projectID));
    } else {
      setLoading(false);
      if (project) {
        setName(project.name);
        setComposer(project.composer);
        setContractor(project.contractor);
        setAgreement(project.agreement);
        setNotes(project.emailNotes);
        setDescription(project.description);
      }
    }
  }, [project]);

  const classes = useStyles(styles);
  const theme = useTheme();

  const updateProject = (name, value) => () => {
    dispatch(Actions.updateProject(projectID, { [name]: value })).then(() =>
      dispatch(Actions.getProjectById(projectID))
    );
  };

  const primaryColor = theme.palette.primary.color[600];

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
        <br />
        <Typography variant="caption" color="textSecondary">
          Loading...
        </Typography>
      </div>
    );
  }

  return (
    <div className="step">
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            Let's talk about your project
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Project Name"
            variant="outlined"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={updateProject("name", name)}
            placeholder="Example: Star Wars"
            fullWidth
            inputProps={{ style: { color: primaryColor } }}
            className="textfield"
          />
          <Typography
            variant="caption"
            color="textSecondary"
            className="p-left"
          >
            Required
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Composer"
            variant="outlined"
            size="small"
            value={composer}
            onBlur={updateProject("composer", composer)}
            onChange={(e) => setComposer(e.target.value)}
            placeholder="Example: John Williams"
            inputProps={{ style: { color: primaryColor } }}
            fullWidth
            className="textfield"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Contractor"
            variant="outlined"
            size="small"
            value={contractor}
            onBlur={updateProject("contractor", contractor)}
            onChange={(e) => setContractor(e.target.value)}
            inputProps={{ style: { color: primaryColor } }}
            placeholder="Example: John Doe"
            fullWidth
            className="textfield"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Wage Scale Agreement & Overtime"
            variant="outlined"
            size="small"
            multiline
            rowsMax={3}
            rows={2}
            onBlur={updateProject("agreement", agreement)}
            value={agreement}
            inputProps={{ style: { color: primaryColor } }}
            onChange={(e) => setAgreement(e.target.value)}
            placeholder="Example: SRLA Base $434.21/3 hour 12.81% Pension $26/H&W "
            fullWidth
            className="textfield"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Private notes for the concierge"
            variant="outlined"
            size="small"
            multiline
            rowsMax={3}
            rows={2}
            value={description}
            onBlur={updateProject("description", description)}
            style={{ background: "#fffde7" }}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Anything you want to share to help us build the call?"
            fullWidth
            className="textfield"
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">Communicating with Musicians </Typography>
          <Typography variant="caption" gutterBottom color="textSecondary">
            Is there anything you want to include in the call?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <RichText
              value={notes}
              onSave={(v) => {
                setNotes(v);
                updateProject("emailNotes", v)();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
