import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const projectAlternates = `${REACT_APP_API_PREFIX}/${service}/projectAlternates`;

export function getProjectAlternates(filters) {
  const url = `${projectAlternates}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getProjectAlternateById(id) {
  const url = `${projectAlternates}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProjectAlternate(id, body) {
  const url = `${projectAlternates}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProjectAlternate(id) {
  const url = `${projectAlternates}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProjectAlternate(body) {
  const url = `${projectAlternates}`;
  return {
    method: POST,
    url,
    body,
  };
}
