// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import FormMusician from "hooks/FormMusician";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function PrimeSelect({ prime = true, onChange }) {
  const [value, setValue] = React.useState(prime);
  const theme = useTheme();

  React.useEffect(() => {
    setValue(prime);
  }, [prime]);

  const primaryColor = "#D7B740";
  return (
    <ButtonGroup
      size="small"
      color="primary"
      aria-label="outlined primary button group"
    >
      <Button
        className="flex centerV centerH"
        onClick={() => {
          setValue(true);
          onChange(true);
        }}
        style={{
          fontSize: 11,
          color: "black",
          borderColor: "black",
          background: prime ? "#eeeeee" : undefined,
        }}
      >
        <i class="fas fa-star"></i>Primary
      </Button>
      <Button
        className="flex centerH centerV"
        onClick={() => {
          setValue(false);
          onChange(false);
        }}
        style={{
          fontSize: 11,
          color: "black",
          borderColor: "black",
          background: !prime ? "#eeeeee" : undefined,
        }}
      >
        <i class="fad fa-repeat"></i> Alternate
      </Button>
    </ButtonGroup>
  );
}

export default function SectionMusicianPanel({
  sectionMusician,
  onClose,
  projectID,
  setShowList,
}) {
  const [formMusicianOpen, setFormMusicianOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState(
    sectionMusician?.musician?.firstName ?? ""
  );
  const [lastName, setLastName] = React.useState(
    sectionMusician?.musician?.lastName ?? ""
  );
  const [email, setEmail] = React.useState(
    sectionMusician?.musician?.email ?? ""
  );
  const [phone, setPhone] = React.useState(
    sectionMusician?.musician?.phone ?? ""
  );

  const [sectionOrder, setSectionOrder] = React.useState(
    sectionMusician?.sectionOrder + 1 ?? ""
  );
  const [sectionRoleID, setSectionRoleID] = React.useState(
    sectionMusician?.sectionRoleID ?? ""
  );
  const [prime, setPrime] = React.useState(sectionMusician?.prime ?? false);
  const [notes, setNotes] = React.useState(sectionMusician?.notes ?? "");

  const classes = useStyles(styles);
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];

  const sectionRoles = useSelector((state) => state.sectionRoles);
  const dispatch = useDispatch();

  const updateMusician = (name, value) => () => {
    dispatch(
      Actions.updateMusician(sectionMusician.musician.id, {
        [name]: value,
      })
    ).then(() => {
      dispatch(Actions.getProjectSections(projectID));
    });
  };

  const updateProjectAlternate = (name, value) => () => {
    dispatch(
      Actions.updateProjectAlternate(sectionMusician.id, {
        [name]: value,
      })
    ).then(() => {
      dispatch(Actions.getProjectSections(projectID));
    });
  };

  React.useEffect(() => {
    console.log(sectionMusician);
    if (sectionMusician?.musician?.id) {
      const musician = sectionMusician?.musician;
      setFirstName(musician.firstName ?? "");
      setLastName(musician.lastName ?? "");
      setEmail(musician.email ?? "");
      setPhone(musician.phone ?? "");
      setSectionOrder(sectionMusician.sectionOrder + 1 ?? "");
      setSectionRoleID(sectionMusician.sectionRoleID ?? "");
      setPrime(sectionMusician.prime);
      setNotes(sectionMusician.notes ?? "");
    }
  }, [sectionMusician]);

  const deletePosition = () => {
    dispatch(Actions.deleteProjectAlternate(sectionMusician.id)).then(() => {
      onClose();
      dispatch(Actions.getProjectSections(projectID));
    });
  };

  const removeMusician = () => {
    dispatch(
      Actions.updateProjectAlternate(sectionMusician.id, { musicianID: -1 })
    ).then(() => {
      dispatch(Actions.getProjectSections(projectID));
    });
  };

  return (
    <Grid
      container
      justify="space-between"
      spacing={2}
      style={{ maxWidth: 600, margin: "auto" }}
    >
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <PrimeSelect
          prime={sectionMusician.prime}
          onChange={(e) => {
            setPrime(e);
            updateProjectAlternate("prime", e)();
          }}
        />
        <div>
          <Chip
            size="small"
            onClick={deletePosition}
            label={
              <span>
                <i class="fad fa-chair p-right"></i>Delete Position
              </span>
            }
          />
        </div>
      </Grid>
      {sectionMusician.musician.id ? (
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">
            {sectionMusician.musician.firstName}{" "}
            {sectionMusician.musician.lastName}
          </Typography>
          <IconButton size="small" onClick={() => setFormMusicianOpen(true)}>
            <Icon className="fad fa-pencil" fontSize="inherit" />
          </IconButton>
          {sectionMusician.musician.id ? (
            <Chip
              size="small"
              onClick={removeMusician}
              style={{ marginLeft: 8 }}
              variant="outlined"
              label={<span>Remove</span>}
            />
          ) : (
            []
          )}
        </Grid>
      ) : (
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">
            <i class="fad fa-chair"></i> Empty Position
          </Typography>
          {!sectionMusician.musician.id || sectionMusician.musician.id <= 0 ? (
            <Chip
              onClick={() => setShowList(true)}
              style={{
                marginLeft: 8,
                background: primaryColor,
                marginLeft: 4,
              }}
              size="small"
              color="primary"
              label={
                <span>
                  <i class="fad fa-user-music p-right"></i>Assign
                </span>
              }
            />
          ) : (
            []
          )}
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          SelectProps={{
            classes: {
              select: classes.select,
              root: classes.select,
              selectMenu: classes.select,
            },
          }}
          label="Role"
          style={{
            background: "rgba(255,255,255,1)",
            borderRadius: 4,
          }}
          inputProps={{
            style: { color: primaryColor },
          }}
          variant="outlined"
          size="small"
          onBlur={updateProjectAlternate(
            "sectionRoleID",
            Number(sectionRoleID)
          )}
          value={sectionRoleID}
          onChange={(e) => setSectionRoleID(e.target.value)}
        >
          {sectionRoles.map((sr) => (
            <MenuItem value={sr.id}>{sr.name}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Order"
          style={{
            background: "rgba(255,255,255,1)",
            borderRadius: 4,
          }}
          onBlur={updateProjectAlternate(
            "sectionOrder",
            Number(sectionOrder - 1)
          )}
          value={sectionOrder}
          onChange={(e) => setSectionOrder(e.target.value)}
          inputProps={{
            style: { color: primaryColor },
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          style={{
            background: "#fffde7",
            borderRadius: 4,
          }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          onBlur={updateProjectAlternate("notes", notes)}
          multiline
          rows={2}
          size="small"
          placeholder="Anything special we need to know about this position/musician?"
          label="Private notes for the concierge"
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Chip
          onClick={onClose}
          label="Close"
          style={{ flex: 1, marginRight: 4 }}
        />
      </Grid>
      <FormMusician
        selectedMusician={sectionMusician?.musician}
        refresh={() => dispatch(Actions.getProjectSections(projectID))}
        open={formMusicianOpen}
        onClose={() => {
          setFormMusicianOpen(false);
        }}
      />
    </Grid>
  );
}
