import { Checkbox, Chip, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TreeItem, TreeView } from "@material-ui/lab";
import * as Actions from "actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const useStyles = makeStyles(styles);

export default function WhosPlaying({ projectID, onSubmit, whosPlayingMeta }) {
  const classes = useStyles(styles);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [families, setFamilies] = React.useState([]);
  const [allNodes, setAllNodes] = React.useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sections, setSections] = React.useState([]);
  const projectSections = useSelector((state) => state.projectSections);
  const sectionRoles = useSelector((state) => state.sectionRoles);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const families = [];
    const allNodes = [];
    const _projectSections = JSON.parse(JSON.stringify(projectSections));
    setLoading(false);
    setSections(_projectSections);
    let meta = {};
    try {
      meta = JSON.parse(whosPlayingMeta) || {};
    } catch (error) {
      console.log("Couldn't parse whosPlayingMeta");
    }

    for (const key in _projectSections) {
      if (Object.hasOwnProperty.call(_projectSections, key)) {
        const section = _projectSections[key];
        section.checked = meta[`s-${section.id}`];
        section.projectMusicians?.forEach(
          (pm) => (pm.checked = meta[`m-${pm.id}`])
        );
        allNodes.push(`s${section.id}`);
        const index = families.findIndex((f) => f.id === section.familyID);
        if (index !== -1) {
          families[index].sections.push(section);
        } else {
          allNodes.push(`f${section.familyID}`);
          families.push({
            name: section.familyName,
            id: section.familyID,
            sections: [section],
            checked: meta[`f-${section.familyID}`],
          });
        }
      }
    }
    setFamilies(families);
    setAllNodes(allNodes);
  }, [projectSections, whosPlayingMeta]);

  React.useEffect(() => {
    if (sectionRoles.length === 0) {
      dispatch(Actions.getSectionRoles());
    }
  }, [sectionRoles]);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const primaryColor = theme.palette.primary.color[600];

  const onCheck = (type, value, checked) => {
    const _families = [...families];
    let family;
    let section;
    switch (type) {
      case "family":
        family = _families.find((f) => f.id === value.id);
        family.checked = checked;
        family?.sections?.forEach((s) => {
          s.checked = checked;
          s.projectMusicians
            ?.filter((m) => m.prime)
            .forEach((m) => (m.checked = checked));
        });
        break;

      case "section":
        family = _families.find((f) => f.id === value.familyID);
        section = family.sections.find((s) => s.id === value.id);
        section.checked = checked;
        section.projectMusicians
          ?.filter((m) => m.prime)
          .forEach((m) => (m.checked = checked));
        break;

      case "musician":
        for (const key in _families) {
          if (Object.hasOwnProperty.call(_families, key)) {
            const f = _families[key];
            for (const key in f.sections) {
              if (Object.hasOwnProperty.call(f.sections, key)) {
                const s = f.sections[key];
                for (const key in s.projectMusicians) {
                  if (Object.hasOwnProperty.call(s.projectMusicians, key)) {
                    const m = s.projectMusicians[key];
                    if (m.id === value.id) {
                      m.checked = checked;
                      break;
                    }
                  }
                }
              }
            }
          }
        }
        break;

      default:
        break;
    }

    setFamilies(_families);
  };

  const getCount = () => {
    let count = 0;
    families.forEach((f) =>
      f.sections.forEach((s) =>
        s.projectMusicians.forEach((m) => {
          if (m.checked) count += 1;
        })
      )
    );
    return count;
  };

  const count = getCount();

  const submit = () => {
    const _families = JSON.parse(JSON.stringify(families));
    const _ret = {};
    for (const key in _families) {
      if (Object.hasOwnProperty.call(_families, key)) {
        const f = _families[key];
        if (f.checked) _ret[`f-${f.id}`] = true;
        for (const key in f.sections) {
          if (Object.hasOwnProperty.call(f.sections, key)) {
            const s = f.sections[key];
            if (s.checked) _ret[`s-${s.id}`] = true;
            for (const key in s.projectMusicians) {
              if (Object.hasOwnProperty.call(s.projectMusicians, key)) {
                const m = s.projectMusicians[key];
                if (m.checked) _ret[`m-${m.id}`] = true;
              }
            }
          }
        }
      }
    }
    onSubmit(_ret);
  };

  return (
    <div>
      <Chip
        onClick={handleClickOpen}
        size="small"
        style={{
          background: count ? primaryColor : undefined,
          color: count ? "white" : undefined,
        }}
        label={
          <span>
            {count ? (
              <span>
                {`${count} musician${count > 1 ? "s" : ""}`}
                <i class="fas fa-sort-down p-left"></i>
              </span>
            ) : (
              <span>
                {" "}
                Who's playing? <i class="fas fa-sort-down p-left"></i>
              </span>
            )}
          </span>
        }
      />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Select who's playing in this session?
        </DialogTitle>
        <DialogContent>
          <TreeView
            disableSelection
            className={classes.root}
            defaultExpanded={allNodes}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {families.map((f, i) => (
              <TreeItem
                nodeId={`f${f.id}`}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        checked={f.checked}
                        onClick={(e) => {
                          console.log("Checkbox");
                        }}
                        key={f.checked}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          onCheck("family", f, e.target.checked);
                        }}
                        size="small"
                      />
                    </div>
                    <Typography variant="h6">{f.name}</Typography>
                  </div>
                }
              >
                {f.sections?.map((s, j) => (
                  <TreeItem
                    nodeId={`s${s.id}`}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox
                            checked={s.checked}
                            key={s.checked}
                            onClick={(e) => {
                              console.log("Checkbox");
                            }}
                            onChange={(e) => {
                              console.log(e.target.checked);
                              onCheck("section", s, e.target.checked);
                            }}
                            size="small"
                          />
                        </div>
                        <Typography variant="subtitle1">{s.name}</Typography>
                      </div>
                    }
                  >
                    {s.projectMusicians
                      .filter((m) => m.prime)
                      .map((m) => (
                        <TreeItem
                          nodeId={`m${m.id}`}
                          style={{
                            margin: "6px 0px",
                          }}
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Checkbox
                                  key={m.checked}
                                  checked={m.checked}
                                  onClick={(e) => {
                                    console.log("Checkbox");
                                  }}
                                  onChange={(e) => {
                                    console.log(e.target.checked);
                                    onCheck("musician", m, e.target.checked);
                                  }}
                                  size="small"
                                />
                              </div>
                              <Typography variant="body2">
                                <b>
                                  {m.musician.id ? (
                                    `${m.musician.firstName} ${m.musician.lastName}`
                                  ) : (
                                    <span>
                                      <i class="fad fa-chair p-right"></i> Empty
                                      Position
                                    </span>
                                  )}
                                </b>{" "}
                                {` - ${
                                  sectionRoles.find(
                                    (sr) => sr.id === m.sectionRoleID
                                  )?.name ?? ""
                                }`}
                              </Typography>
                            </div>
                          }
                        />
                      ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              submit();
              handleClose(e);
            }}
            color="primary"
            variant="contained"
            style={{ background: primaryColor }}
            autoFocus
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
