import { DATA, DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const projects = `/${service}/projects`;

export function getProjects() {
  const url = `${projects}`;
  return {
    method: GET,
    url,
  };
}

export function uploadProjectLogo(projectID, logo) {
  const data = new FormData();
  data.append("file", logo, "logo.png");

  const url = `${projects}/${projectID}/uploadLogo`;
  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getProjectStudioSessions(id) {
  const url = `${projects}/${id}/workSessions`;
  return {
    method: GET,
    url,
  };
}

export function getProjectLayout(id) {
  const url = `${projects}/${id}/layout`;
  return {
    method: GET,
    url,
  };
}

export function projectAutofill(id) {
  const url = `${projects}/${id}/autofill`;
  return {
    method: GET,
    url,
  };
}

export function getProjectStatuses(id) {
  const url = `${projects}/${id}/statuses`;
  return {
    method: GET,
    url,
  };
}

export function getProjectSections(id) {
  const url = `${projects}/${id}/sections`;
  return {
    method: GET,
    url,
  };
}

export function getProjectById(id) {
  const url = `${projects}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProject(id, body) {
  const url = `${projects}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProject(id) {
  const url = `${projects}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProject(body) {
  const url = `${projects}`;
  return {
    method: POST,
    url,
    body,
  };
}
