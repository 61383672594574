import parsePhoneNumber from "libphonenumber-js";
import React from "react";

export default function FormattedPhone({ value }) {
  const [parse, setParse] = React.useState(null);
  React.useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        console.log(phoneNumber);
        setParse(phoneNumber);
      }
    }
  }, [value]);

  return <span>{parse?.formatNational() ?? ""}</span>;
}
