import * as Api from "api";
import {
  CREATE_LINK,
  DELETE_LINK,
  GET_LINKS,
  GET_LINK_BY_ID,
  UPDATE_LINK,
  UPLOAD,
} from "constants/links";
import { networkAction } from "helpers/network/networkAction";

export const getLinks = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_LINKS, Api.getLinks, [filters]);

export const getLinkById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LINK_BY_ID, Api.getLinkById, [id]);

export const deleteLink = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_LINK, Api.deleteLink, [id]);

export const createLink = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_LINK, Api.createLink, [body]);

export const updateLink = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_LINK, Api.updateLink, [id, body]);

export const upload = (file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD, Api.upload, [file]);
