import { GET_QUOTE_BY_REF } from "constants/quotes";

export default (state = null, action) => {
  switch (action.type) {
    case `${GET_QUOTE_BY_REF}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
