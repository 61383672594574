// import PropTypes from 'prop-types';
// material-ui
import { Grid, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function FormLink({
  open,
  onClose,
  selectedLink,
  sessionID,
  refreshWorkSession,
}) {
  const [url, setUrl] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [error, setError] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userStudios = useSelector((state) => state.studios);
  const classes = useStyles(styles);
  const primaryColor = theme.palette.primary.color[600];
  const dispatch = useDispatch();

  React.useEffect(() => {
    setName(selectedLink?.name ?? "");
    setDescription(selectedLink?.description ?? "");
    setUrl(selectedLink?.url ?? "");
    setError("");
  }, [selectedLink]);

  let schema = yup.object().shape({
    description: yup.string(),
    name: yup.string().required(),
    url: yup.string().required(),
    global: yup.bool().required(),
    sessionID: yup.number().required(),
  });

  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setError(
        "You can upload only 1 file at the time. Archive all you files in a zip and retry, or create as many link as you have files."
      );
    } else {
      dispatch(Actions.upload()).then((r) => {
        setUrl(r.payload);
      });
    }
    console.log("acceptedFiles", acceptedFiles);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function save() {
    const b = {
      description,
      name,
      global: true,
      url,
      sessionID,
    };
    console.log("save");
    setError("");
    schema
      .validate(b)
      .catch(function (err) {
        setError(err.errors);
        console.log(err);
        return;
      })
      .then((body) => {
        console.log(body);
        if (!body) return;
        if (selectedLink) {
          dispatch(Actions.updateLink(selectedLink.id, b)).then(
            refreshWorkSession
          );
          onClose();
        } else {
          dispatch(Actions.createLink(b)).then(refreshWorkSession);
          onClose();
        }
        setDescription("");
        setUrl("");
        setName("");
      });
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {name || "New Link"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="What is this attachement?"
                fullWidth
                inputProps={{ style: { color: primaryColor } }}
                className="textfield"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                size="small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Why musicians need to download it?"
                fullWidth
                inputProps={{ style: { color: primaryColor } }}
                className="textfield"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Url"
                variant="outlined"
                size="small"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Google Drive, Dropbox, DocuSign..."
                fullWidth
                inputProps={{ style: { color: primaryColor } }}
                className="textfield"
              />
              <Typography variant="caption">
                You can use your own link or{" "}
                <u {...getRootProps()} style={{ cursor: "pointer" }}>
                  <input {...getInputProps()} />
                  <i class="fad fa-cloud-upload-alt p-right"></i>upload a file
                </u>
              </Typography>
            </Grid>
            {error ? (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={save}
          color="primary"
          autoFocus
          style={{ background: primaryColor }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
