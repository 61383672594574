import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const instrumentSections = `/${service}/instrumentSections`;

export function getInstrumentSections() {
  const url = `${instrumentSections}`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentSectionById(id) {
  const url = `${instrumentSections}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentSectionMusicians(id, sessionID, sectionRoleID) {
  const url = `${instrumentSections}/${id}/musicians?sessionID=${sessionID}${
    sectionRoleID ? `&sectionRoleID=${sectionRoleID}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentSectionProjectMusicians(id, projectID) {
  const url = `${instrumentSections}/${id}/alternates?projectID=${projectID}`;
  return {
    method: GET,
    url,
  };
}

export function updateInstrumentSection(id, body) {
  const url = `${instrumentSections}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInstrumentSection(id) {
  const url = `${instrumentSections}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInstrumentSection(body) {
  const url = `${instrumentSections}`;
  return {
    method: POST,
    url,
    body,
  };
}
