// import PropTypes from 'prop-types';
// material-ui
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import WorkSession from "hooks/WorkSession/WorkSession";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function WorkSessions({ projectID }) {
  const ws = useSelector((state) => state.workSessions);
  const [gotIt, setGotIt] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [workSessions, setWorkSessions] = React.useState(ws);
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  React.useEffect(() => {
    setWorkSessions(ws);
  }, []);

  React.useEffect(() => {
    if (workSessions === null) {
      dispatch(Actions.getProjectStudioSessions(projectID));
    } else {
      setLoading(false);
    }
  }, [workSessions]);

  const JSX = [];

  if (workSessions) {
    for (let index = 0; index < workSessions.length; index++) {
      JSX.push(
        <Grid item xs={12}>
          <WorkSession
            workSessionID={workSessions[index].id}
            projectID={projectID}
          />
        </Grid>
      );
    }
  }

  for (
    let index = workSessions?.length ?? 0;
    index < quote.workSessionCount;
    index++
  ) {
    JSX.push(
      <Grid item xs={12}>
        <WorkSession projectID={projectID} />
      </Grid>
    );
  }

  return (
    <div className="step">
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            Work Sessions{" "}
            <i
              onClick={() => setGotIt(false)}
              style={{ cursor: "pointer" }}
              class="fad fa-question-circle"
            ></i>
          </Typography>
        </Grid>
        {!gotIt ? (
          <Grid item xs={12}>
            <Alert
              severity="info"
              action={
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setGotIt(true)}
                >
                  Got it
                </Button>
              }
            >
              A work session is your musicians (or a group of them) playing on a
              single date and location.
              <br />
              <Typography
                variant="caption"
                style={{ opacity: 0.58, fontSize: 10 }}
              >
                Examples of 2 Work Sessions:
                <ul style={{ paddingLeft: 40, opacity: 0.58 }}>
                  <li>Monday 01/08 8-12PM: Brass Only. @Capitol Records</li>
                  <li>Monday 01/08 8-5PM: Strings (Double). @Fox Studio</li>
                </ul>
              </Typography>
            </Alert>
          </Grid>
        ) : (
          []
        )}
        {loading ? (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress />
            <br />
            <Typography variant="caption" color="textSecondary">
              Loading...
            </Typography>
          </Grid>
        ) : (
          JSX
        )}
      </Grid>
    </div>
  );
}
