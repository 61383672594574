import { GET_MUSICIANS } from "constants/musicians";

export default (
  state = { data: [], refreshKey: new Date().getTime() },
  action
) => {
  switch (action.type) {
    case `${GET_MUSICIANS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
