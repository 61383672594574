// import PropTypes from 'prop-types';
// material-ui
import { Grid, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import DateInput from "hooks/DateInput";
import StudioFinder from "hooks/StudioFinder";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function FormRehearsal({
  open,
  onClose,
  selectedRehearsal,
  sessionID,
  refreshWorkSession,
}) {
  const [studio, setStudio] = React.useState();
  const [date, setDate] = React.useState({ dateFromUTC: "", dateToUTC: "" });
  const [overtime, setOvertime] = React.useState("");
  const [noTimeRange, setNoTimeRange] = React.useState(false);
  const [tzName, setTzName] = React.useState("America/Los_Angeles");
  const [description, setDescription] = React.useState("");
  const [key, setKey] = React.useState(new Date().getTime());
  const [error, setError] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userStudios = useSelector((state) => state.studios);
  const classes = useStyles(styles);
  const primaryColor = theme.palette.primary.color[600];
  const dispatch = useDispatch();

  React.useEffect(() => {
    setStudio(selectedRehearsal?.studio ?? null);
    setDescription(selectedRehearsal?.description ?? "");
    setOvertime(selectedRehearsal?.overtime ?? "");
    setNoTimeRange(selectedRehearsal?.noTimeRange ?? false);
    setTzName(selectedRehearsal?.tzName ?? "");
    setError("");
    setDate({
      dateFromUTC: selectedRehearsal?.dateFromUTC ?? "",
      dateToUTC: selectedRehearsal?.dateToUTC ?? "",
    });
    setKey(new Date().getTime());
  }, [selectedRehearsal]);

  let schema = yup.object().shape({
    description: yup.string(),
    overtime: yup.string(),
    venueID: yup.number().required("Studio is required"),
    noTimeRange: yup.bool().required(),
    dateFromUTC: yup.string().required("Date and Time From is required"),
    dateToUTC: yup.string().required("Time To is required"),
    tzName: yup.string().required("Tzname is required"),
    isEvent: yup.bool().required(),
  });

  function save() {
    const b = {
      description,
      overtime,
      venueID: studio?.id,
      noTimeRange,
      dateFromUTC: date.dateFromUTC,
      dateToUTC: date.dateToUTC,
      tzName,
      isEvent: false,
      sessionID,
      name: "Rehearsal",
    };
    setError("");
    schema
      .validate(b)
      .catch(function (err) {
        setError(err.errors);
        return;
      })
      .then((body) => {
        if (!body) return;
        if (selectedRehearsal) {
          dispatch(Actions.updateEngagement(selectedRehearsal.id, b)).then(
            refreshWorkSession
          );
          close();
        } else {
          dispatch(Actions.createEngagement(b)).then(refreshWorkSession);
          close();
        }
      });
  }

  function close() {
    onClose();
    setStudio(null);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {date?.dateFromUTC ? (
          <>
            {moment(date.dateFromUTC).format("lll")} -{" "}
            {moment(date.dateToUTC).format("H:mm A")}
          </>
        ) : (
          "New Rehearsal"
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StudioFinder
                studios={studio ? [studio] : []}
                setStudios={(e) => {
                  if (e.length) {
                    setStudio(e[0]);
                    setTzName(e[0].tzName);
                  }
                }}
              />
              {!studio ? (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ cursor: "pointer", fontSize: 10 }}
                >
                  Required
                </Typography>
              ) : (
                []
              )}
            </Grid>
            <Grid item xs={12}>
              <DateInput
                dateFromUTC={date.dateFromUTC}
                dateToUTC={date.dateToUTC}
                key={key}
                tzName={tzName}
                noTimeRange={noTimeRange}
                error={moment(date.dateToUTC).isBefore(
                  moment(date.dateFromUTC)
                )}
                onChange={(from, to) => {
                  setDate({ dateFromUTC: from, dateToUTC: to });
                }}
              />
              <Typography
                variant="caption"
                color="textSecondary"
                onClick={() => setNoTimeRange(!noTimeRange)}
                style={{ cursor: "pointer", fontSize: 10 }}
              >
                {!date ? `Required | ` : ""}
                <u>{!noTimeRange ? "No Time Range" : "Time Range"}</u>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                size="small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="What is this rehearsal about?"
                fullWidth
                inputProps={{ style: { color: primaryColor } }}
                className="textfield"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Overtime"
                variant="outlined"
                size="small"
                value={overtime}
                onChange={(e) => setOvertime(e.target.value)}
                placeholder="How will it be handle?"
                fullWidth
                inputProps={{ style: { color: primaryColor } }}
                className="textfield"
              />
            </Grid>
            {error ? (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={save}
          color="primary"
          autoFocus
          style={{ background: primaryColor }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
