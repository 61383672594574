import MomentUtils from "@date-io/moment";
import {
  Chip,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Actions from "actions";
import { getLatLgn, getTimezone } from "helpers";
import DateInput from "hooks/DateInput";
import FormLink from "hooks/FormLink";
import FormRehearsal from "hooks/FormRehearsal";
import Link from "hooks/Link";
import Rehearsal from "hooks/Rehearsal";
import StudioFinder from "hooks/StudioFinder";
import WhosPlaying from "hooks/WhosPlaying";
// import PropTypes from 'prop-types';
// material-ui
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function WorkSession({
  workSessionID,
  projectID,
  onCreate,
  checkForErrors,
  errorFeedback,
  onDelete,
}) {
  // Work Session Values
  const [workSession, setWorkSession] = React.useState(null);
  const [rehearsals, setRehearsals] = React.useState([]);
  const [links, setLinks] = React.useState([]);
  const [studio, setStudio] = React.useState();
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [whosPlayingMeta, setWhosPlayingMeta] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [noVenue, setNoVenue] = React.useState(false);
  const [sessionTypeID, setSessionTypeID] = React.useState(1);
  const [engagements, setEngagements] = React.useState([]);
  const [noTimeRange, setNoTimeRange] = React.useState(false);
  const [tzName, setTzName] = React.useState("America/Los_Angeles");
  const [date, setDate] = React.useState({ dateFromUTC: "", dateToUTC: "" });

  // Internal Values
  const [formRehearsalOpen, setFormRehearsalOpen] = React.useState(false);
  const [selectedRehearsal, setSelectedRehearsal] = React.useState(null);
  const [formLinkOpen, setFormLinkOpen] = React.useState(false);
  const [selectedLink, setSelectedLink] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [key, setKey] = React.useState(new Date().getTime());
  const [tzNameRefresh, setTzNameRefresh] = React.useState(0);
  const [error, setError] = React.useState("");

  const userStudios = useSelector((state) => state.studios);
  const sessionTypes = useSelector((state) => state.sessionTypes);
  const classes = useStyles(styles);
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string(),
    whosPlayingMeta: yup.string(),
    sessionTypeID: yup.number(),
    projectID: yup.number(),
    mainEngagement: yup
      .object()
      .shape({
        tzName: yup.string().required(),
        city: yup.string().required(),
        venueID: yup.number(),
        state: yup.string().required(),
        noTimeRange: yup.bool().required(),
        dateFromUTC: yup.string().required("Date From is Required"),
        dateToUTC: yup.string().required("Date To is Required"),
      })
      .required(),
  });

  // Init
  React.useEffect(() => {
    if (!userStudios) {
      dispatch(Actions.getStudios());
    }
    if (!sessionTypes) {
      dispatch(Actions.getSessionTypes());
    }
  }, []);

  // We get the worksession object as soon as we get the id
  React.useEffect(() => {
    if (workSessionID && !workSession) {
      refreshWorkSession();
    }
  }, [workSessionID]);

  // We set all individual values as soon as we get the work session object
  React.useEffect(() => {
    if (workSession && workSession.id) {
      setTitle(workSession.title);
      setWhosPlayingMeta(workSession.whosPlayingMeta);
      setDescription(workSession.description);
    }
  }, [workSession]);

  // We get the timezone based on city and state
  React.useEffect(() => {
    console.log(tzNameRefresh, `${city} ${state}`);
    if (city && state) {
      const a = `${city} ${state}`;
      console.log("Fetching...");
      getLatLgn(a).then((coordinates) => {
        console.log(coordinates);
        if (coordinates.lat && coordinates.lng) {
          getTimezone(coordinates.lat, coordinates.lng).then((tz) => {
            console.log(tz);
            setTzName(tz);
            if (workSession && workSession.id) {
              save("mainEngagement", { tzName: tz })();
            }
          });
        }
      });
    }
  }, [tzNameRefresh]);

  // We get the work session engagements when the work session object changes
  React.useEffect(() => {
    if (workSession && workSession.id) {
      dispatch(Actions.getStudioSessionEngagements(workSession.id)).then(
        (r) => {
          if (r.success) setEngagements(r.payload);
        }
      );
    }
  }, [workSession]);

  // We get the work session links when the work session object changes
  React.useEffect(() => {
    if (workSession && workSession.id) {
      dispatch(
        Actions.getLinks([
          { name: "sessionID", comparison: "eq", value: workSession.id },
        ])
      ).then((r) => setLinks(r.payload));
    }
  }, [workSession]);

  // Engagements changes
  React.useEffect(() => {
    const _mainEngagement = engagements.find((e) => e.isEvent);
    const _rehearsals = engagements.filter((e) => e.isEvent !== true);
    if (_rehearsals?.length) {
      for (const key in _rehearsals) {
        if (Object.hasOwnProperty.call(_rehearsals, key)) {
          const _rehearsal = _rehearsals[key];
          const s = userStudios?.find((s) => s.id === _rehearsal.venueID);
          _rehearsal.studio = s;
        }
      }
      setRehearsals(_rehearsals);
    }
    if (_mainEngagement) {
      const s = userStudios?.find((s) => s.id === _mainEngagement.venueID);
      setStudio({ ...s, prevent: true });
      setTzName(_mainEngagement?.tzName ?? "America/Los_Angeles");
      setDate({
        dateFromUTC: _mainEngagement.dateFromUTC,
        dateToUTC: _mainEngagement.dateToUTC,
      });
      setKey(new Date().getTime());
    }
  }, [engagements, userStudios]);

  // Studio has changed
  React.useEffect(() => {
    if (studio && !studio.prevent) {
      save("mainEngagement", {
        venueID: studio?.id ?? -1,
        city: studio?.city ?? "",
        state: studio?.state ?? "",
        tzName: studio?.tzName ?? "",
      })();
    }
  }, [studio]);

  // Date has changed
  React.useEffect(() => {
    if (
      !workSession ||
      workSession.dateFromUTC !== date.dateFromUTC ||
      workSession.dateToUTC !== date.dateToUTC
    ) {
      save("mainEngagement", { ...date })();
    }
  }, [date]);

  // WhosPlayingMeta has changed
  React.useEffect(() => {
    if (
      workSession &&
      workSession.id &&
      workSession.whosPlayingMeta !== whosPlayingMeta
    )
      dispatch(
        Actions.updateStudioSession(workSession.id, { whosPlayingMeta })
      );
  }, [whosPlayingMeta]);

  // Error Checker. The parent can ask to check and display errors.
  React.useEffect(() => {
    if (checkForErrors) {
      hasErrors();
    }
  }, [checkForErrors]);

  function refreshWorkSession() {
    dispatch(
      Actions.getStudioSessionById(workSessionID || workSession.id)
    ).then((r) => {
      if (r.success) setWorkSession(r.payload);
    });
  }

  const save = (field, value) => () => {
    if (workSession?.id) {
      dispatch(Actions.updateStudioSession(workSession.id, { [field]: value }));
    } else {
      schema
        .validate(getBody())
        .catch(function (err) {})
        .then((b) => {
          if (!b) return;
          if (b) setError();
          setDisabled(true);
          dispatch(Actions.createStudioSession(b)).then((r) => {
            setDisabled(false);
            if (r.success) {
              setWorkSession(r.payload);
              onCreate && onCreate(r.payload);
            }
          });
          errorFeedback && errorFeedback(false);
        });
    }
  };

  function getBody() {
    const body = {
      title,
      description,
      whosPlayingMeta,
      sessionTypeID,
      projectID,
      mainEngagement: {
        tzName,
        city,
        state,
        venueID: studio?.id,
        ...date,
        noTimeRange,
      },
    };
    console.log(body);
    return body;
  }

  function hasErrors() {
    const body = getBody();

    schema
      .validate(body)
      .catch(function (err) {
        setError(err.errors);
        errorFeedback && errorFeedback(true);
      })
      .then((b) => {
        if (b) setError();
        errorFeedback && errorFeedback(false);
      });
  }

  function deleteEngagement(e) {
    dispatch(Actions.deleteEngagement(e.id)).then(refreshWorkSession);
  }

  function deleteLink(e) {
    dispatch(Actions.deleteLink(e.id)).then(refreshWorkSession);
  }

  return (
    <div className={classes.container} style={{ position: "relative" }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ExpansionPanel
          elevation={workSession?.id ? 1 : 4}
          defaultExpanded
          style={{ borderLeft: error ? "solid 4px #f44336" : undefined }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid
              container
              justify="space-between"
              style={{ flex: 1 }}
              alignItems="center"
            >
              <Grid item style={{ flexGrow: 1, display: "flex" }}>
                <Typography className={classes.heading}>
                  {workSession?.id && whosPlayingMeta !== "{}" ? (
                    <Tooltip title="We have all we need for this session">
                      <div style={{ display: "inline-block" }}>
                        <i
                          style={{ color: primaryColor }}
                          class="fas fa-badge-check p-right"
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    error && (
                      <Tooltip title="Session can't be created because of errors. Expand to see what's wrong.">
                        <div style={{ display: "inline-block" }}>
                          <i
                            style={{ color: "#f44336" }}
                            class="far fa-exclamation-triangle"
                          ></i>
                        </div>
                      </Tooltip>
                    )
                  )}
                  <b>{title || "Untitled Work Session"}</b>
                  <Typography variant="caption" display="block">
                    {date.dateFromUTC && date.dateToUTC ? (
                      <div>
                        {moment(date.dateFromUTC).format("lll")} -{" "}
                        {moment(date.dateToUTC).format("H:mm A")} ({tzName})
                      </div>
                    ) : (
                      []
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    <i class="fad fa-map-marker-alt p-right"></i>{" "}
                    {studio ? (
                      <span>
                        {studio.name}, {studio.zipcode} {studio.city}
                      </span>
                    ) : (
                      "Unkown Location"
                    )}
                  </Typography>
                  {rehearsals.length ? (
                    <Typography variant="caption" color="textSecondary">
                      <i class="fad fa-ear p-right"></i>
                      {rehearsals.length} Rehearsal
                      {rehearsals.length > 1 ? "s" : ""}
                    </Typography>
                  ) : (
                    []
                  )}
                  {links.length ? (
                    <Typography variant="caption" color="textSecondary">
                      <i class="fad fa-paperclip p-right p-left"></i>
                      {links.length} Attachment
                      {links.length > 1 ? "s" : ""}
                    </Typography>
                  ) : (
                    []
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "right" }}>
                <WhosPlaying
                  projectID={projectID}
                  whosPlayingMeta={whosPlayingMeta}
                  onSubmit={(e) => setWhosPlayingMeta(JSON.stringify(e))}
                />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              style={{ flex: 1 }}
              spacing={2}
              alignItems="center"
              justify="space-between"
            >
              {error ? (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              ) : (
                []
              )}
              <Grid item xs={12}>
                <TextField
                  label="Session Type"
                  variant="outlined"
                  size="small"
                  select
                  disabled={disabled}
                  value={sessionTypeID}
                  onChange={(e) => setSessionTypeID(e.target.value)}
                  onBlur={save("sessionTypeID", sessionTypeID)}
                  placeholder="General or AFM"
                  fullWidth
                  InputProps={{ style: { color: primaryColor } }}
                  className="textfield"
                >
                  {sessionTypes.map((st) => (
                    <MenuItem
                      value={st.id}
                      style={{ color: st.id === sessionTypeID && primaryColor }}
                    >
                      {st.code}: {st.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  variant="outlined"
                  size="small"
                  disabled={disabled}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={save("title", title)}
                  placeholder="Example: Violins, Brass"
                  fullWidth
                  inputProps={{ style: { color: primaryColor } }}
                  className="textfield"
                />
                {!title ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ cursor: "pointer", fontSize: 10 }}
                  >
                    Required
                  </Typography>
                ) : (
                  []
                )}
              </Grid>
              <Grid item xs={12}>
                <DateInput
                  dateFromUTC={date.dateFromUTC}
                  dateToUTC={date.dateToUTC}
                  disabled={disabled}
                  key={key}
                  tzName={tzName}
                  noTimeRange={noTimeRange}
                  error={moment(date.dateToUTC).isBefore(
                    moment(date.dateFromUTC)
                  )}
                  onChange={(from, to) => {
                    setDate({ dateFromUTC: from, dateToUTC: to });
                  }}
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  onClick={() => {
                    const v = !noTimeRange;
                    setNoTimeRange(v);
                    save("mainEngagement", { noTimeRange: v })();
                  }}
                  style={{ cursor: "pointer", fontSize: 10 }}
                >
                  {!date ? `Required | ` : ""}
                  <u>{!noTimeRange ? "No Time Range" : "Time Range"}</u>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {noVenue ? (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="City"
                        variant="outlined"
                        size="small"
                        disabled={disabled}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        onBlur={() => {
                          save("mainEngagement", { city })();
                          setTzNameRefresh(tzNameRefresh + 1);
                        }}
                        placeholder="Los Angeles, New York..."
                        fullWidth
                        inputProps={{ style: { color: primaryColor } }}
                        className="textfield"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="State"
                        variant="outlined"
                        size="small"
                        disabled={disabled}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        onBlur={() => {
                          save("mainEngagement", { state })();
                          setTzNameRefresh(tzNameRefresh + 1);
                        }}
                        placeholder="California, Oregon..."
                        fullWidth
                        inputProps={{ style: { color: primaryColor } }}
                        className="textfield"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <StudioFinder
                    studios={studio ? [studio] : []}
                    disabled={disabled}
                    setStudios={(e) => {
                      setState(e[0]?.state);
                      setCity(e[0]?.city);
                      setTzName(e[0]?.tzName ?? "America/Los_Angeles");
                      setStudio(e[0]);
                    }}
                  />
                )}
                {!studio ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ cursor: "pointer", fontSize: 10 }}
                  >
                    Required
                  </Typography>
                ) : (
                  []
                )}
                <br />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ cursor: "pointer", fontSize: 10 }}
                  onClick={() => setNoVenue(!noVenue)}
                >
                  <u>{!noVenue ? "No Venue" : "Select a Venue"}</u>
                </Typography>
              </Grid>
              {workSession?.id ? (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <i class="fad fa-ear p-right"></i>Rehearsals
                    </Typography>
                  </Grid>
                  <Grid item style={{ textAlign: "right" }}>
                    <Chip
                      label="+ Rehearsal"
                      size="small"
                      onClick={() => setFormRehearsalOpen(true)}
                    />
                  </Grid>
                  {rehearsals.length ? (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {rehearsals.map((r) => (
                          <Grid item xs={12} key={r.id}>
                            <Rehearsal
                              value={r}
                              onDelete={() => {
                                deleteEngagement(r);
                              }}
                              onEdit={() => {
                                setSelectedRehearsal(r);
                                setFormRehearsalOpen(true);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    []
                  )}
                </>
              ) : (
                []
              )}
              <div style={{ flexBasis: "100%", height: 0 }} />
              {workSession?.id ? (
                <>
                  <Grid item>
                    <Typography>
                      <i class="fad fa-paperclip p-right"></i>Attachments
                    </Typography>
                  </Grid>
                  <Grid item style={{ textAlign: "right" }}>
                    <Chip
                      label="+ Attachment"
                      size="small"
                      onClick={() => setFormLinkOpen(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {links.map((r) => (
                        <Grid item key={r.id}>
                          <Link
                            value={r}
                            onDelete={() => {
                              deleteLink(r);
                            }}
                            onEdit={() => {
                              setSelectedLink(r);
                              setFormLinkOpen(true);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ) : (
                []
              )}
              <Grid item xs={12}>
                <TextField
                  label="Internal notes"
                  variant="outlined"
                  size="small"
                  multiline
                  disabled={disabled}
                  rowsMax={3}
                  rows={2}
                  value={description}
                  onBlur={save("description", description)}
                  style={{ background: "#fffde7" }}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Anything useful for your own organization"
                  fullWidth
                  className="textfield"
                />
              </Grid>
              <Grid item />
              {onDelete ? (
                <Grid item>
                  <Chip
                    onClick={onDelete}
                    variant="outlined"
                    label={
                      <span>
                        <i class="far fa-times-circle"></i> Remove Work Session
                      </span>
                    }
                    size="small"
                  />
                </Grid>
              ) : (
                []
              )}
            </Grid>
            <FormRehearsal
              open={formRehearsalOpen}
              sessionID={workSession?.id}
              selectedRehearsal={selectedRehearsal}
              onClose={() => {
                setSelectedRehearsal(null);
                setFormRehearsalOpen(false);
              }}
              refreshWorkSession={refreshWorkSession}
            />
            <FormLink
              open={formLinkOpen}
              sessionID={workSession?.id}
              selectedLink={selectedLink}
              onClose={() => {
                setSelectedLink(null);
                setFormLinkOpen(false);
              }}
              refreshWorkSession={refreshWorkSession}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </MuiPickersUtilsProvider>
    </div>
  );
}
