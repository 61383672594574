// import PropTypes from 'prop-types';
// material-ui
import { Button, Chip, Grid, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as Actions from "actions";
import { REACT_APP_API } from "config";
import moment from "moment";
import React from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Review() {
  const [accepted, setAccepted] = React.useState(false);
  const classes = useStyles(styles);
  const theme = useTheme();

  const projectSections = useSelector((state) => state.projectSections);
  const workSessions = useSelector((state) => state.workSessions);
  const project = useSelector((state) => state.project);
  const quote = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  let count = 0;

  for (const key in projectSections) {
    if (Object.hasOwnProperty.call(projectSections, key)) {
      const section = projectSections[key];
      for (const key in section.projectMusicians) {
        if (Object.hasOwnProperty.call(section.projectMusicians, key)) {
          const projectMusician = section.projectMusicians[key];
          if (projectMusician.prime) count += 1;
        }
      }
    }
  }

  React.useEffect(() => {
    console.log(count, workSessions.length);
    if (
      quote.musicianCount !== count ||
      quote.workSessionCount !== workSessions.length
    ) {
      dispatch(
        Actions.updateQuote(quote.id, {
          musicianCount: count,
          workSessionCount: workSessions.length,
        })
      ).then(() => {
        dispatch(Actions.getQuoteByRef(quote.reference));
      });
    }
  }, []);

  const primaryColor = theme.palette.primary.color[600];

  const addToCalendar = () => {
    const ics = require("ics");
    const events = [];

    for (const key in workSessions) {
      if (workSessions.hasOwnProperty(key)) {
        const workSession = workSessions[key];
        events.push({
          start: moment(workSession.dateFromUTC)
            .format("YYYY-M-D-H-m")
            .split("-"),
          end: moment(workSession.dateToUTC).format("YYYY-M-D-H-m").split("-"),
          title: `${workSession.title}`,
          location: workSession.studioName,
          categories: ["Rhapsody"],
        });
      }
    }

    ics.createEvents(events, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      const blob = new Blob([value], { type: "text/calendar" });
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${project.name} by ${project.contractor}`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    });
  };

  function acceptQuote() {
    setAccepted(true);
    dispatch(
      Actions.updateQuote(quote.id, {
        status: "pending",
      })
    );

    for (const key in workSessions) {
      if (Object.hasOwnProperty.call(workSessions, key)) {
        const workSession = workSessions[key];
        dispatch(Actions.processMetadata(workSession.id));
      }
    }
  }

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className="step">
      {!accepted ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Summary</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                border: "solid 1px #2196f3",
                background: "#e3f2fd",
                textAlign: "center",
                padding: 16,
                borderRadius: 4,
                color: "#2196f3",
              }}
            >
              <Typography variant="h2" style={{ fontWeight: 600 }}>
                {quote.musicianCount}
              </Typography>
              <Typography variant="body2">Musicians</Typography>
              <Chip
                style={{ background: primaryColor, color: "white" }}
                size="small"
                onClick={() =>
                  window.open(
                    `${REACT_APP_API}/rhapsody/projects/roster?token=${project.publicToken}`
                  )
                }
                label={
                  <span>
                    <i class="fas fa-cloud-download p-right"></i>Download Roster
                  </span>
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                border: "solid 1px #2196f3",
                background: "#e3f2fd",
                textAlign: "center",
                padding: 16,
                borderRadius: 4,
                color: "#2196f3",
              }}
            >
              <Typography variant="h2" style={{ fontWeight: 600 }}>
                {quote.workSessionCount}
              </Typography>
              <Typography variant="body2">Work Sessions</Typography>
              <Chip
                style={{ background: primaryColor, color: "white" }}
                size="small"
                onClick={addToCalendar}
                label={
                  <span>
                    <i class="fas fa-calendar-plus p-right"></i>Add to Calendar
                  </span>
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}></Grid>
          <Grid item xs={12}>
            <Typography variant="h6">What to expect next</Typography>
            <ol>
              <li>
                A dedicated concierge will contact you to review your project.
              </li>
              <li>
                Optional: a test call email will be sent to you for approval.
              </li>
              <li>We send the work call.</li>
              <li>You have access to the hiring status in live, 24/7.</li>
              <li>
                We replace declined musicians with their alternates until the
                hiring is completed.
              </li>
              <li>We send you the final rosters for each sessions.</li>
              <li>We send a reminded to everyone before every sessions</li>
              <li>Optional: We take care of the payroll forms</li>
            </ol>
          </Grid>
          <Grid item xs={12} style={{ lineHeight: 1 }}>
            <Typography variant="caption" color="textSecondary">
              <i class="fad fa-handshake-alt"></i> <b>Privacy:</b> The content
              of this project is strictly confidential. We never share its
              content or any related informations. You musician database is
              encrypted, secured and exclusive to you. Contacted musicians won't
              know their position number and won't know if they are primary or
              alternate. Don't hesitate to ask the concierge if you have any
              concerns.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ lineHeight: 1 }}>
            <Typography variant="h6">
              Total: {formatter.format(quote.total)}
            </Typography>
            <Typography variant="caption" style={{ fontSize: 10 }}>
              Total is always recalculated based on provided data. It might
              differ from the quote you received by email.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Button
              fullWidth
              onClick={acceptQuote}
              style={{ background: primaryColor, color: "white" }}
            >
              Accept Quote
            </Button>
          </Grid>
          <Grid xs={12} style={{ textAlign: "center" }}>
            <Typography variant="caption" color="primary">
              An invoice will be sent when the hiring is completed
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "20vh" }}>
            <Typography variant="h3">You are all set!</Typography>
            <br />
            <Typography variant="body1">
              Thanks for using Rhapsody!
              <br />
              We will get back to you within {quote.urgent ? "1h" : "24h"}.
            </Typography>
            <Typography variant="caption" color="textSecondary">
              You can close this page
            </Typography>
          </Grid>
        </Grid>
      )}
      {accepted ? (
        <div style={{ position: "fixed", top: 0, width: "100vh", left: 0 }}>
          <Confetti
            recycle={false}
            gravity={0.2}
            tweenDuration={4000}
            numberOfPieces={1000}
          />
        </div>
      ) : (
        []
      )}
    </div>
  );
}
