// import PropTypes from 'prop-types';
// material-ui
import { DialogContent, Typography, useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function RichText({ onSave, value }) {
  let es;
  const theme = useTheme();
  if (value) {
    const html = value;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      es = EditorState.createWithContent(contentState);
    }
  }
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editorState, setEditorState] = React.useState(
    es ? es : () => EditorState.createEmpty()
  );
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(styles);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onSave(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setOpen(false);
  };

  const primaryColor = theme.palette.primary.color[600];
  const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  return (
    <div>
      <div
        onClick={handleClickOpen}
        style={{
          border: "1px solid #bdbdbd",
          borderRadius: 4,
          maxHeight: 300,
          background: "white",
          overflow: "scroll",
          padding: 8,
          cursor: "pointer",
        }}
      >
        {html.length <= 8 ? (
          <Typography color="textSecondary">Notes for Musicians</Typography>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        aria-labelledby="alert-dialog-title"
        maxWidth="md"
        fullWidth
        classes={{
          paper: classes.paper,
        }}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: 0, display: "flex" }}>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            variant="contained"
            color="secondary"
            style={{
              background: primaryColor,
              color: "white",
              width: "100%",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
