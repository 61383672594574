import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import FormattedPhone from "hooks/FormattedPhone";
import GoogleContacts from "hooks/GoogleContacts";
import InstrumentSelect from "hooks/InstrumentSelect";
import PhoneInput from "hooks/PhoneInput";
import VcfContacts from "hooks/VcfContacts";
import { isValidPhoneNumber } from "libphonenumber-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FormMusician({
  open,
  onClose,
  refresh,
  selectedMusician,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [contacts, setContacts] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emails, setEmails] = React.useState([]);
  const [phones, setPhones] = React.useState([]);
  const [phone, setPhone] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [instrumentsOpen, setInstrumentOpen] = React.useState(false);
  const [musicianInstruments, setMusicianInstruments] = React.useState([]);
  const instruments = useSelector((state) => state.instruments);
  const primaryColor = theme.palette.primary.color[600];
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (instruments.length === 0) {
      dispatch(Actions.getInstruments());
    }

    if (selectedMusician) {
      initMusician(selectedMusician);
    }
  }, [selectedMusician]);

  const initMusician = (selectedMusician) => {
    setEmails(selectedMusician?.emails ?? []);
    setPhones(selectedMusician?.phones ?? []);
    setFirstName(selectedMusician.firstName || "");
    setLastName(selectedMusician.lastName || "");
    setEmail(selectedMusician.email || "");
    setMusicianInstruments(selectedMusician.instruments ?? []);
    setPhone(selectedMusician.phone);
  };

  let schema = yup.object().shape({
    musicianInstruments: yup
      .array()
      .min(1, "Musician needs to play at least 1 instrument")
      .required(),
    phone: yup
      .string()
      .test("phone", "${path} format is not recognized", (value) => {
        console.log(value);
        debugger;
        if (value === "") return true;
        return isValidPhoneNumber(value);
      }),
    email: yup.string().email().required(),
    lastName: yup.string().min(1, "Last Name is required").required(),
    firstName: yup.string().min(1, "First Name is required").required(),
  });

  const save = () => {
    setError("");
    schema
      .validate({
        firstName,
        lastName,
        email,
        phone,
        musicianInstruments,
      })
      .catch(function (err) {
        setError(err.errors);
        return;
      })
      .then((body) => {
        if (!body) return;
        if (!selectedMusician?.id) {
          setLoading(true);
          dispatch(
            Actions.createMusician({
              firstName,
              lastName,
              email,
              phone,
              active: true,
            })
          ).then((r) => {
            if (r.success) {
              const musician = r.payload;
              const proms = [];
              for (const key in musicianInstruments) {
                if (Object.hasOwnProperty.call(musicianInstruments, key)) {
                  const instru = musicianInstruments[key];
                  proms.push(
                    dispatch(
                      Actions.createMusicianInstrument(musician.id, {
                        instrumentID: instru.id,
                      })
                    )
                  );
                }
              }

              Promise.all(proms).then(() => {
                if (contacts.length > 1) {
                  const _contacts = [...contacts];
                  _contacts.shift();
                  initMusician(_contacts[0]);
                  setLoading(false);
                  setContacts(_contacts);
                } else {
                  close();
                }
                refresh();
              });
            }
          });
        } else {
          setLoading(true);
          dispatch(
            Actions.updateMusician(selectedMusician.id, {
              firstName,
              lastName,
              email,
              phone,
              active: true,
            })
          ).then((r) => {
            setLoading(false);
            if (r.success) {
              close();
              refresh();
            }
          });
        }
      });
  };

  const removeMusicianInstrument = (instru, i) => {
    if (i) {
      const _mui = [...musicianInstruments];
      _mui.splice(i, 1);
      setMusicianInstruments(_mui);
    }

    if (selectedMusician?.id) {
      dispatch(
        Actions.deleteMusicianInstrument(selectedMusician.id, {
          instrumentID: instru.id,
        })
      );
    }
  };

  const addMusicianInstrument = (i) => {
    const _mui = [...musicianInstruments, i];
    setMusicianInstruments(_mui);
    if (selectedMusician?.id) {
      dispatch(
        Actions.createMusicianInstrument(selectedMusician.id, {
          instrumentID: i.id,
        })
      );
    }
  };

  const deleteMusician = () => {
    dispatch(Actions.deleteMusician(selectedMusician.id)).then(() => {
      close();
      refresh();
    });
  };

  const close = () => {
    onClose();
    setFirstName("");
    setLastName("");
    setMusicianInstruments([]);
    setPhone("");
    setEmail("");
    setPhones([]);
    setEmails([]);
    setError("");
    setLoading(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography variant="h6">
          {selectedMusician?.id ? "Edit Musician" : "New Musician"}
        </Typography>
        {!selectedMusician?.id ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <GoogleContacts
              onSelect={(e) => {
                setContacts(e);
                if (e?.length) {
                  initMusician(e[0]);
                }
              }}
            />
            <VcfContacts
              onSelect={(e) => {
                setContacts(e);
                console.log(e);
                if (e?.length) {
                  initMusician(e[0]);
                }
              }}
            />
          </div>
        ) : (
          []
        )}
      </DialogTitle>
      <DialogContent style={{ opacity: loading ? 0.5 : 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              helperText="Required"
              disabled={loading}
              variant="outlined"
              style={{
                background: "rgba(255,255,255,1)",
                borderRadius: 4,
              }}
              inputProps={{
                style: { color: primaryColor },
              }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              size="small"
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              helperText="Required"
              variant="outlined"
              disabled={loading}
              style={{
                background: "rgba(255,255,255,1)",
                borderRadius: 4,
              }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              inputProps={{
                style: { color: primaryColor },
              }}
              size="small"
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={loading}
              helperText={!email && emails.length === 0 ? "Required" : ""}
              variant="outlined"
              style={{
                background: "rgba(255,255,255,1)",
                borderRadius: 4,
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{
                style: { color: primaryColor },
              }}
              size="small"
              label="Email"
            />
            {emails.length > 1 ? (
              <Typography variant="caption" style={{ fontSize: 10 }}>
                Or{" "}
                {emails
                  .filter((e) => e !== email)
                  .map((e) => (
                    <span
                      onClick={() => setEmail(e)}
                      style={{
                        cursor: "pointer",
                        background: "#e1f5fe",
                        border: "solid 1px #03a9f4",
                        marginLeft: 2,
                        borderRadius: 20,
                        padding: "0px 4px",
                        color: "#0288d1",
                      }}
                    >
                      {e}
                    </span>
                  ))}
                <i class="fad fa-exclamation-triangle p-righ p-left"></i> Select
                the correct email
              </Typography>
            ) : (
              []
            )}
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              fullWidth
              disabled={loading}
              variant="outlined"
              style={{
                background: "white",
                borderRadius: 4,
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                style: { color: primaryColor },
              }}
              size="small"
              label="Phone"
              helperText={
                !phone && phones.length === 0
                  ? "Provide a valid phone number for SMS"
                  : ""
              }
            />
            {phones.length > 1 ? (
              <Typography variant="caption" style={{ fontSize: 10 }}>
                Or{" "}
                {phones
                  .filter((e) => e !== phone)
                  .map((e) => (
                    <span
                      onClick={() => setPhone(e)}
                      style={{
                        cursor: "pointer",
                        background: "#e1f5fe",
                        border: "solid 1px #03a9f4",
                        marginLeft: 2,
                        borderRadius: 20,
                        padding: "0px 4px",
                        color: "#0288d1",
                      }}
                    >
                      <FormattedPhone value={e} />
                    </span>
                  ))}{" "}
                <i class="fad fa-exclamation-triangle p-righ p-left"></i> Select
                the correct phone
              </Typography>
            ) : (
              []
            )}
            <InstrumentSelect
              open={instrumentsOpen}
              selected={musicianInstruments}
              onChange={(e) => setMusicianInstruments(e)}
              onAdd={(e) => e?.forEach((i) => addMusicianInstrument(i))}
              onDelete={(e) => e?.forEach((i) => removeMusicianInstrument(i))}
              onClose={() => setInstrumentOpen(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              disabled={loading}
              style={{
                background: "white",
                borderRadius: 4,
                cursor: "pointer",
              }}
              // value={phone}
              // onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                style: { color: primaryColor },
              }}
              size="small"
              label="Click here to add Instruments"
              disabled
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setInstrumentOpen(true);
              }}
            />
            <br />
            {musicianInstruments.length ? (
              <Grid container spacing={1} style={{ marginTop: 8 }}>
                {musicianInstruments.map((mi, i) => (
                  <Grid item key={mi.id}>
                    <Chip
                      label={mi.name}
                      size="small"
                      onDelete={() => {
                        removeMusicianInstrument(mi, i);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              []
            )}
            <br />
            <Divider />
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingTop: 8,
        }}
      >
        <Button autoFocus onClick={close} variant="contained">
          Cancel
        </Button>
        {contacts.length > 1 ? (
          <Typography>{contacts.length - 1} other to review</Typography>
        ) : (
          []
        )}
        {selectedMusician?.id ? (
          <Button
            autoFocus
            onClick={deleteMusician}
            variant="outlined"
            style={{ color: "#f44336", borderColor: "#f44336" }}
          >
            Delete
          </Button>
        ) : (
          []
        )}
        <Button
          onClick={close}
          color="primary"
          autoFocus
          variant="contained"
          onClick={save}
          style={{ background: primaryColor }}
        >
          {contacts.length > 1 ? "Next" : "Save"}
          {loading ? (
            <CircularProgress
              style={{
                color: "white",
                height: 18,
                width: 18,
                marginLeft: 20,
              }}
            />
          ) : (
            []
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
