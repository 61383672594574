import { REACT_APP_API_PREFIX } from "config/env";
import { POST } from "constants/methods";

const service = "account";
const users = `${REACT_APP_API_PREFIX}/${service}/users`;

export function signinPL(body) {
  const url = `${users}/signinPL`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
