import MomentUtils from "@date-io/moment";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Alert from "@material-ui/lab/Alert";
// import PropTypes from 'prop-types';
// material-ui
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import moment from "moment-timezone";
import React from "react";

export default function DateTime({
  dateFromUTC,
  dateToUTC,
  tzName,
  onChange,
  noTimeRange,
  disabled,
  error,
}) {
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [f, setF] = React.useState(
    dateFromUTC ? moment.tz(dateFromUTC, tzName) : null
  );
  const [t, setT] = React.useState(
    dateToUTC ? moment.tz(dateToUTC, tzName) : null
  );

  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];

  const handleFChange = (_f) => {
    if (!f && !t) {
      _f = _f.startOf("day").add(8, "hour");
    }
    setF(_f);
    let newT = _f.clone();
    if (!t) {
      setT(newT);
    } else {
      // We need to change the T date
      const hours = t.format("HH");
      const minutes = t.format("mm");
      newT = newT
        .startOf("day")
        .add(parseInt(hours), "hour")
        .add(parseInt(minutes), "minutes");
      setT(newT);
    }

    onChange(_f.clone().utc().format(), newT.clone().utc().format());
  };

  const handleTChange = (_t) => {
    setT(_t);
    onChange(f.clone().utc().format(), _t.clone().utc().format());
  };

  const tError = moment(t).isBefore(moment(f));
  const mobile = !useMediaQuery("(min-width:600px)");

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid
        container
        spacing={2}
        onClick={!tzName ? () => setSnackOpen(true) : undefined}
      >
        <Grid
          item
          style={{ flexGrow: 1 }}
          xs={noTimeRange ? 12 : mobile ? 12 : 4}
        >
          <DatePicker
            inputVariant="outlined"
            variant={"dialog"}
            value={f}
            onChange={(_f) => handleFChange(_f.clone())}
            placeholder="MM/DD/YYYY"
            fullWidth
            error={error}
            label="Date"
            disabled={disabled || !tzName}
            showTodayButton
            size="small"
            inputProps={{
              style: { color: primaryColor },
            }}
            autoOk
            keyboardIcon={<CalendarTodayIcon style={{ fontSize: 20 }} />}
            KeyboardButtonProps={{ size: "small" }}
            format="MM/DD/YYYY"
          />
        </Grid>
        {!noTimeRange ? (
          <>
            <Grid item xs={mobile ? 6 : 4}>
              <TimePicker
                variant={"dialog"}
                inputVariant="outlined"
                error={error}
                value={f}
                fullWidth
                onChange={(_f) => handleFChange(_f.clone())}
                placeholder="HH:MM AM"
                disabled={disabled || !f || !tzName}
                label="From"
                autoOk
                inputProps={{
                  style: { color: primaryColor },
                }}
                size="small"
                keyboardIcon={<ScheduleIcon style={{ fontSize: 20 }} />}
                KeyboardButtonProps={{ size: "small" }}
              />
            </Grid>
            <Grid item xs={mobile ? 6 : 4}>
              <TimePicker
                variant={"dialog"}
                error={tError || error}
                inputVariant="outlined"
                value={t}
                label="To"
                autoOk
                inputProps={{
                  style: { color: primaryColor },
                }}
                fullWidth
                onChange={(_t) => handleTChange(_t.clone())}
                placeholder="HH:MM AM"
                disabled={disabled || !f || !tzName}
                size="small"
                keyboardIcon={<ScheduleIcon style={{ fontSize: 20 }} />}
                KeyboardButtonProps={{ size: "small" }}
              />
            </Grid>
          </>
        ) : (
          []
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="warning">
          Please Choose a Venue First.
        </Alert>
      </Snackbar>
    </MuiPickersUtilsProvider>
  );
}
