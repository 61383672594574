import { DATA, DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const links = `/${service}/links`;
const uploads = `/${service}/uploads`;

export function getLinks(filters) {
  const url = filters
    ? `${links}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`
    : `${links}`;
  return {
    method: GET,
    url,
  };
}

export function getLinkById(id) {
  const url = `${links}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLink(id, body) {
  const url = `${links}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLink(id) {
  const url = `${links}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLink(body) {
  const url = `${links}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function upload(file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${uploads}`;

  return {
    method: DATA,
    url,
    body: data,
  };
}
