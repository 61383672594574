import {
  Avatar,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import * as Actions from "actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function LayoutBuilder({
  open,
  projectID,
  onClose,
  onSubmit,
  type,
  sortAlphabetically = false,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const instrumentSections = useSelector((state) => state.instrumentSections);
  const instrumentFamilies = useSelector((state) => state.instrumentFamilies);
  const [positions, setPositions] = React.useState([]);
  const [prime, setPrime] = React.useState(true);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const inputEl = React.useRef(null);
  const [selectedFamily, setSelectedFamily] = React.useState(
    instrumentFamilies && instrumentFamilies.length
      ? instrumentFamilies[0]
      : undefined
  );

  React.useEffect(() => {
    if (instrumentSections.length === 0) {
      dispatch(Actions.getInstrumentSections());
    }
  }, [instrumentSections]);

  React.useEffect(() => {
    if (instrumentFamilies.length === 0) {
      dispatch(Actions.getInstrumentFamilies());
    } else {
      setSelectedFamily(instrumentFamilies[0]);
    }
  }, [instrumentFamilies]);

  const add = (sectionID, familyID) => {
    const _positions = positions.slice(0); // eslint-disable-line
    const existingIndex = _positions.findIndex(
      (p) => p.sectionID === sectionID
    );
    if (existingIndex !== -1) {
      _positions[existingIndex].createCount += 1;
    } else {
      _positions.push({
        sectionID,
        projectID,
        familyID,
        prime: type === "primary",
        createCount: 1,
      });
    }

    setPositions(_positions);
  };

  const remove = (sectionID) => {
    let _positions = positions.slice(0); // eslint-disable-line
    const existingIndex = _positions.findIndex(
      (p) => p.sectionID === sectionID
    );
    if (existingIndex !== -1) {
      _positions[existingIndex].createCount -= 1;
      if (_positions[existingIndex].createCount === 0) {
        _positions.splice(existingIndex, 1);
      }
    }
    setPositions(_positions);
  };

  const removeFamily = (family) => {
    let _positions = []; // eslint-disable-line
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        const p = positions[key];
        if (p.familyID !== family.id) {
          _positions.push(p);
        }
      }
    }
    setPositions(_positions);
  };

  const onChange = (sectionID, familyID, value) => {
    if (!isNaN(value)) {
      let _positions = positions.slice(0); // eslint-disable-line
      const existingIndex = _positions.findIndex(
        (p) => p.sectionID === sectionID
      );
      if (existingIndex !== -1) {
        _positions[existingIndex].createCount = Number(value);
        if (_positions[existingIndex].createCount === 0) {
          _positions.splice(existingIndex, 1);
        }
      } else {
        _positions.push({
          sectionID,
          projectID,
          familyID,
          prime: type === "primary",
          createCount: Number(value),
        });
      }
      setPositions(_positions);
    }
  };

  let sortedInstrumentFamilies = instrumentFamilies || [];
  let sortedInstrumentSection = [];

  if (sortAlphabetically && instrumentFamilies) {
    sortedInstrumentFamilies = instrumentFamilies.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  if (selectedFamily) {
    sortedInstrumentSection =
      instrumentSections &&
      instrumentSections.filter((s) => s.familyID === selectedFamily.id);
    if (sortAlphabetically) {
      sortedInstrumentSection = sortedInstrumentSection.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
  }

  const createProjectAlternates = () => {
    dispatch(Actions.createProjectAlternate(positions)).then(() => {
      onSubmit();
      setPositions([]);
      onClose();
    });
  };

  const color = type === "alternate" ? "#607d8b" : "#2196f3";

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        onClose();
        setPositions([]);
      }}
      scroll={"paper"}
      maxWidth={"sm"}
      fullWidth
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        How many {type} positions?
        <br />
        <Typography variant="caption" color="textSecondary">
          An Alternate will be contacted if a primary declined.
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{ height: !fullScreen ? 500 : undefined, padding: 10 }}
      >
        <Grid container>
          <Grid
            item
            xs={4}
            style={{
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              height: !fullScreen ? 500 : undefined,
              overflow: "scroll",
            }}
          >
            <List dense style={{ marginRight: 10 }}>
              {sortedInstrumentFamilies.map((f) => {
                const instrumentsSectionCount =
                  instrumentSections &&
                  instrumentSections.filter((s) => s.familyID === f.id).length;
                let selectedInstrumentsSectionCount = 0;

                if (positions.length) {
                  selectedInstrumentsSectionCount = positions.reduce((a, p) => {
                    const toAdd = p.familyID === f.id ? p.createCount : 0;
                    return a + toAdd;
                  }, 0);
                }

                if (instrumentsSectionCount) {
                  return (
                    <ListItem
                      button
                      key={f.id}
                      style={{
                        background:
                          selectedFamily && selectedFamily.id === f.id
                            ? color
                            : undefined,
                        color:
                          selectedFamily && selectedFamily.id === f.id
                            ? "white"
                            : undefined,
                        borderRadius: 4,
                      }}
                      onClick={() => {
                        setSelectedFamily(f);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography variant={mobile ? "caption" : "body2"}>
                            {f.name}
                          </Typography>
                        }
                        secondary={
                          selectedInstrumentsSectionCount ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  fontSize: 10,
                                  color:
                                    selectedFamily && selectedFamily.id === f.id
                                      ? "rgba(255,255,255,0.6)"
                                      : color,
                                }}
                              >
                                {`${selectedInstrumentsSectionCount} Position${
                                  selectedInstrumentsSectionCount > 1 ? "s" : ""
                                } `}
                              </Typography>
                              <Tooltip title={`Remove ${f.name} Positions`}>
                                <Close
                                  style={{
                                    fontSize: 12,
                                    border: "solid 1px rgba(0,0,0,0.3)",
                                    borderRadius: 30,
                                    marginLeft: 4,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    removeFamily(f);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )
                        }
                      />
                    </ListItem>
                  );
                }
                return [];
              })}
            </List>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              height: !fullScreen ? 500 : undefined,
              overflow: "scroll",
            }}
          >
            {selectedFamily ? (
              <List>
                {sortedInstrumentSection.map((s) => {
                  const existingPostions = positions.find(
                    (p) => p.sectionID === s.id
                  );
                  return (
                    <ListItem divider>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 0,
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant={mobile ? "caption" : "body2"}
                            noWrap
                          >
                            {s.name}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: 110,
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item>
                              <IconButton
                                style={{
                                  border: "solid 1px rgba(155,155,155,0.3)",
                                }}
                                size="small"
                                onClick={() => remove(s.id, s.familyID)}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Avatar
                                size="small"
                                style={{
                                  height: 30,
                                  width: 30,
                                  background:
                                    existingPostions &&
                                    existingPostions.createCount
                                      ? color
                                      : undefined,
                                }}
                              >
                                <InputBase
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                    width: 30,
                                    cursor: "pointer",
                                  }}
                                  ref={inputEl}
                                  onFocus={(e) => e.target.select()}
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  onChange={(e) =>
                                    onChange(s.id, s.familyID, e.target.value)
                                  }
                                  fullWidth
                                  value={`${
                                    (existingPostions &&
                                      existingPostions.createCount) ||
                                    0
                                  }`}
                                />
                              </Avatar>
                            </Grid>
                            <Grid item>
                              <IconButton
                                style={{
                                  border: "solid 1px rgba(155,155,155,0.3)",
                                }}
                                size="small"
                                onClick={() => add(s.id, s.familyID)}
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              []
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              autoFocus
              onClick={() => {
                onClose();
                setPositions([]);
              }}
              variant="contained"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={createProjectAlternates}
              variant="contained"
              autoFocus
              disbled={positions.length === 0}
              style={{
                background: color,
                color: "white",
                textTransform: "none",
                marginLeft: 16,
              }}
            >
              Add{" "}
              {positions.length
                ? `${positions.reduce((a, p) => a + p.createCount, 0)} ${type}`
                : ""}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
