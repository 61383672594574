// import PropTypes from 'prop-types';
// material-ui
import { Icon, IconButton, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Rehearsal({ value, onEdit, onDelete }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];

  return (
    <div
      onClick={onEdit}
      style={{
        background: "rgba(33,150,243,0.1)",
        border: "solid 1px rgba(33,150,243,0.3)",
        color: primaryColor,
        borderRadius: 4,
        padding: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <div>
        <Typography variant="caption" display="block">
          {value.dateFromUTC && value.dateToUTC ? (
            <div>
              {moment(value.dateFromUTC).format("lll")} -{" "}
              {moment(value.dateToUTC).format("H:mm A")}
            </div>
          ) : (
            []
          )}
        </Typography>
        <Typography variant="caption" style={{ opacity: 0.56 }} display="block">
          <i class="fad fa-map-marker-alt p-right"></i>{" "}
          {value.studio ? (
            <span>
              {value.studio.name}, {value.studio.zipcode} {value.studio.city}
            </span>
          ) : (
            "Unkown Location"
          )}
        </Typography>
      </div>
      <div>
        <IconButton size="small" color="inherit">
          <Icon style={{ fontSize: 14 }} className="fas fa-pencil" />
        </IconButton>
        <IconButton
          size="small"
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelete();
          }}
        >
          <Icon style={{ fontSize: 18 }} className="far fa-times" />
        </IconButton>
      </div>
    </div>
  );
}
