import {
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  InputBase,
  useTheme,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import { REACT_APP_LOGO } from "config";
import { EMAIL } from "constants/regexp";
import React from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function PasswordFreeSignin({
  open,
  onClose,
  email,
  onAuthenticated,
  plcode,
}) {
  const [code, setCode] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles(styles);
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[700];
  const ref0 = React.useRef();
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (plcode && plcode.length === 6) {
      submit(plcode);
    }
  }, [plcode]);

  React.useEffect(() => {
    if (open && EMAIL.test(email)) {
      submitEmail(email);
    } else {
      setMail(email);
    }
  }, [open, email]);

  const submit = async (c) => {
    setLoading(true);
    const r = await dispatch(
      Actions.signinPL({
        code: c,
      })
    );
    setError();
    if (r.success) onAuthenticated(r);
    else {
      setLoading(false);
      setError(true);
    }
  };

  const handleChange = (e, i) => {
    const v = e.target.value;
    let newCode;
    if (v.length > 1) {
      newCode = v.substring(0, 6);
      setCode(newCode);
    } else {
      newCode = `${code.substring(0, i)}${
        e.target.value[0] || ""
      }${code.substring(i + 1)}`;
      setCode(newCode);
    }

    if (newCode?.length === 6) {
      ref5.current.focus();
      submit(newCode);
    }

    switch (i) {
      case 0:
        if (v === "") ref0.current.focus();
        else ref1.current.focus();
        break;
      case 1:
        if (v === "") ref0.current.focus();
        else ref2.current.focus();
        break;
      case 2:
        if (v === "") ref1.current.focus();
        else ref3.current.focus();
        break;
      case 3:
        if (v === "") ref2.current.focus();
        else ref4.current.focus();
        break;
      case 4:
        if (v === "") ref3.current.focus();
        else ref5.current.focus();
        break;
      case 5:
        if (v === "") ref4.current.focus();
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (e, i) => {
    if (e.keyCode === 8 && !code[i]) {
      handleChange({ target: { value: "" } }, i);
    }
  };

  const submitEmail = async (m) => {
    setLoading(true);
    const r = await dispatch(
      Actions.requestPLCode({
        email: m,
      })
    );
    setLoading(false);
    if (r.success) {
      setSent(true);
      setUser(r.payload);
    }
  };

  if (!open) return <div></div>;

  let JSX = (
    <div>
      <Typography variant="h6">Authentication</Typography>
      <div style={{ display: "flex" }}>
        <InputBase
          className="twoFactor"
          inputRef={ref0}
          onDrop={(e) => console.log("Drop")}
          autoFocus
          inputProps={{
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false",
            type: "tel",
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            style: { color: primaryColor },
          }}
          value={code[0] ?? ""}
          style={{ borderColor: code[0] ? primaryColor : undefined }}
          onChange={(e) => handleChange(e, 0)}
          onKeyDown={(e) => handleKeyDown(e, 0)}
        />
        <InputBase
          className="twoFactor"
          inputRef={ref1}
          inputProps={{
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false",
            type: "tel",
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            style: { color: primaryColor },
          }}
          value={code[1] ?? ""}
          style={{ borderColor: code[1] ? primaryColor : undefined }}
          onChange={(e) => handleChange(e, 1)}
          onKeyDown={(e) => handleKeyDown(e, 1)}
        />
        <InputBase
          className="twoFactor"
          inputRef={ref2}
          inputProps={{
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false",
            type: "tel",
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            style: { color: primaryColor },
          }}
          value={code[2] ?? ""}
          style={{ borderColor: code[2] ? primaryColor : undefined }}
          onChange={(e) => handleChange(e, 2)}
          onKeyDown={(e) => handleKeyDown(e, 2)}
        />
        <InputBase
          className="twoFactor"
          inputRef={ref3}
          inputProps={{
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false",
            type: "tel",
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            style: { color: primaryColor },
          }}
          style={{ borderColor: code[3] ? primaryColor : undefined }}
          value={code[3] ?? ""}
          onChange={(e) => handleChange(e, 3)}
          onKeyDown={(e) => handleKeyDown(e, 3)}
        />
        <InputBase
          className="twoFactor"
          inputRef={ref4}
          inputProps={{
            autoCorrect: "off",
            autoCapitalize: "off",
            spellcheck: "false",
            type: "tel",
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            style: { color: primaryColor },
          }}
          style={{ borderColor: code[4] ? primaryColor : undefined }}
          value={code[4] ?? ""}
          onChange={(e) => handleChange(e, 4)}
          onKeyDown={(e) => handleKeyDown(e, 4)}
        />
        <InputBase
          className="twoFactor"
          inputRef={ref5}
          inputProps={{
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false",
            type: "tel",
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            style: { color: primaryColor },
          }}
          style={{ borderColor: code[5] ? primaryColor : undefined }}
          value={code[5] ?? ""}
          onChange={(e) => handleChange(e, 5)}
          onKeyDown={(e) => handleKeyDown(e, 5)}
        />
      </div>
      {error ? (
        <Alert style={{ marginTop: 20 }} severity="error">
          The code is incorrect, please retry
        </Alert>
      ) : (
        []
      )}
      <br />
      <Typography variant="body2">
        In order to continue, please enter
        <br />
        the verification code we sent{" "}
        {user?.phone ? `you by SMS to ****${user.phone}` : "by email"}
      </Typography>
      <Typography
        variant="caption"
        onClick={() => setSent(false)}
        style={{
          textDecoration: "underline",
          color: primaryColor,
          cursor: "pointer",
        }}
      >
        Didn't get the code?
      </Typography>
    </div>
  );
  if (loading) JSX = <CircularProgress />;

  return (
    <div className={classes.container}>
      <IconButton
        style={{ position: "absolute", top: 8, right: 8 }}
        onClick={onClose}
      >
        <Icon
          className="fal fa-times"
          style={{ color: "white", fontSize: 32 }}
        />
      </IconButton>
      <div style={{ textAlign: "center" }}>
        {!loading ? (
          <>
            <img src={REACT_APP_LOGO} style={{ width: 200 }} />
            <Divider />
          </>
        ) : (
          []
        )}

        {JSX}
      </div>
    </div>
  );
}
