import {
  Avatar,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Icon,
  MobileStepper,
  StepConnector,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import { REACT_APP_LOGO } from "config";
import { getJsonFromUrl } from "helpers";
import Musicians from "hooks/Musicians";
import Project from "hooks/Project";
import Review from "hooks/Review";
import WorkSessions from "hooks/WorkSessions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepLabel: {
    marginTop: -20,
  },
  stepper: {
    padding: "4px 0px",
  },
  labelContainer: {
    marginTop: 0,
  },
  label: {
    marginTop: "4px !important",
  },
}));

function getSteps() {
  return [
    { name: "Project", icon: "fad fa-folder-open" },
    { name: "Musicians", icon: "fad fa-user-music" },
    { name: "Work Sessions", icon: "fad fa-calendar-day" },
    { name: "Review", icon: "fad fa-flag-checkered" },
  ];
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#272839",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#272839",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export default function Intake({ projectID = 5 }) {
  const classes = useStyles();
  const urlParams = getJsonFromUrl(window.location);
  const [activeStep, setActiveStep] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const [proceed, setProceed] = React.useState(false);
  const steps = getSteps();
  const theme = useTheme();

  const project = useSelector((state) => state.project);
  const projectSections = useSelector((state) => state.projectSections);
  const quote = useSelector((state) => state.quote);
  const dispatch = useDispatch();

  const handleNext = async () => {
    if (proceed) {
      setErrors([]);
      goNext();
      return;
    }
    const e = [];
    switch (activeStep) {
      case 0:
        if (!project.name) {
          e.push("Your project needs a name");
        }
        break;
      case 1:
        // check if all section position are correct
        if (projectSections?.length === 0) {
          e.push(<span>You haven't set any positions is your project.</span>);
        }
        for (const k in projectSections) {
          if (Object.hasOwnProperty.call(projectSections, k)) {
            const projectSection = projectSections[k];
            for (const i in projectSection.projectMusicians) {
              if (
                Object.hasOwnProperty.call(projectSection.projectMusicians, i)
              ) {
                const projectMusician = projectSection.projectMusicians[i];
                if (
                  projectMusician.musicianID <= 0 ||
                  !projectMusician.musicianID
                ) {
                  e.push(
                    <span>
                      <b>{projectSection.name}</b> -{" "}
                      <b>Position {projectMusician.sectionOrder + 1}</b> is
                      empty.
                    </span>
                  );
                } else {
                  if (!projectMusician.musician.email) {
                    e.push(
                      <span>
                        <b>
                          {projectMusician.musician.firstName}{" "}
                          {projectMusician.musician.firstName}
                        </b>{" "}
                        doesn't have an email.
                      </span>
                    );
                  }
                }
              }
            }
          }
        }

        break;
      case 2:
        const r = await dispatch(Actions.getProjectStudioSessions(projectID));
        const ws = r.payload;
        if (ws.length !== quote.workSessionCount) {
          e.push(
            <span>
              It seems like you haven't provided all the details for your work
              sessions. Make sure they all have this badge{" "}
              <i class="fas fa-badge-check"></i>{" "}
            </span>
          );
        }
        for (const key in ws) {
          if (Object.hasOwnProperty.call(ws, key)) {
            const workSession = ws[key];
            if (
              !workSession.whosPlayingMeta ||
              workSession.whosPlayingMeta === "{}"
            ) {
              e.push(
                <span>
                  <b>{workSession.title}</b> Session: We don't know who's
                  playing.
                </span>
              );
            }
          }
        }
      default:
        break;
    }
    setErrors(e);
    if (e.length) setProceed(true);

    if (e.length === 0) goNext();
  };

  const goNext = () => {
    console.log("goNext");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function getStatusMessage(status) {
    switch (status) {
      case "pending":
        return (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6">Your project is {status}</Typography>
            <Typography variant="caption" display="block">
              A concierge is working on your call.
            </Typography>
          </div>
        );
      case "ready":
        return (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6">Your project is {status}</Typography>
            <Typography variant="caption" display="block">
              The concierge has sent the call and manages answers
            </Typography>
          </div>
        );
      case "invoiced":
        return (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6">Your project is {status}</Typography>
            <Typography variant="caption" display="block">
              The hiring is done and we sent you your invoice.
            </Typography>
          </div>
        );
      case "paid":
        return (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6">Your project is {status}</Typography>
            <Typography variant="caption" display="block">
              We have received your payment. We really appreciate working with
              you!
            </Typography>
          </div>
        );

      default:
        break;
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Project projectID={projectID} />;
      case 1:
        return <Musicians projectID={projectID} />;
      case 2:
        return <WorkSessions projectID={projectID} />;
      case 3:
        return <Review projectID={projectID} />;
      default:
        return "Unknown stepIndex";
    }
  }

  const handleBack = () => {
    setErrors([]);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const primaryColor = theme.palette.primary.color[600];

  if (quote?.status !== "draft" && !urlParams.admin) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <>
            <img src={REACT_APP_LOGO} style={{ width: 200 }} />
            <Divider />
          </>
          {getStatusMessage(quote.status)}
          <br />
          <div style={{ padding: 8, color: primaryColor }}>Any Questions?</div>
          <Chip
            style={{
              color: "white",
              background: primaryColor,
            }}
            onClick={() =>
              window.open(
                "https://go.crisp.chat/chat/embed/?website_id=b97797af-c2cb-4f58-8534-7fe935a77e7c",
                "_blank"
              )
            }
            label={
              <span>
                <i class="fas fa-comment p-right"></i>Chat with us
              </span>
            }
          />
        </div>
      </div>
    );
  }

  const maxSteps = 4;
  return (
    <div
      className="flex centerH centerV column"
      style={{ position: "relative" }}
    >
      <div
        style={{
          background: primaryColor,
          color: "white",
          width: "100%",
          textAlign: "center",
          padding: 2,
          cursor: "pointer",
          zIndex: 1300,
        }}
        onClick={() =>
          window.open(
            "https://go.crisp.chat/chat/embed/?website_id=b97797af-c2cb-4f58-8534-7fe935a77e7c",
            "_blank"
          )
        }
      >
        <Typography variant="caption">
          <i class="fad fa-comments p-right"></i>Need help? Chat with us
        </Typography>
      </div>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{
          width: "100%",
          position: "sticky",
          zIndex: 999,
          top: 0,
          borderBottom: "solid 1px rgba(155,155,155,0.3)",
        }}
        classes={{
          root: classes.stepper,
        }}
        connector={<QontoConnector />}
      >
        {steps.map((s, i) => {
          return (
            <Step
              key={s.name}
              classes={{
                root: classes.step,
              }}
            >
              <StepLabel
                classes={{
                  labelContainer: classes.labelContainer,
                  label: classes.label,
                }}
                StepIconComponent={({ active, completed }) => {
                  let color = "#272839";
                  let background;

                  if (active) {
                    color = "white";
                    background = "#2196f3";
                  }
                  if (completed) {
                    color = "white";
                    background = "#272839";
                  }
                  return (
                    <Avatar
                      style={{
                        height: 30,
                        width: 30,
                        background,
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: 15,
                          color,
                        }}
                        className={s.icon}
                      />
                    </Avatar>
                  );
                }}
                style={{ padding: 0 }}
              >
                <Typography variant="caption" style={{ fontSize: 11 }}>
                  {s.name}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div style={{ flexGrow: 1, width: "100%", background: "#fafafa" }}>
        {errors.length ? (
          <div style={{ maxWidth: 600, margin: "auto", marginTop: 20 }}>
            <Alert severity="error">
              <Typography>We identify some issues:</Typography>
              <ul>
                {errors.map((e) => (
                  <li>
                    <Typography variant="caption">{e}</Typography>
                  </li>
                ))}
              </ul>
            </Alert>
          </div>
        ) : (
          []
        )}
        {getStepContent(activeStep)}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          background: "white",
          borderTop: "solid 1px rgba(155,155,155,0.3)",
          paddingBottom: 16,
          zIndex: 99,
        }}
      >
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          style={{ background: "white" }}
          activeStep={activeStep}
          nextButton={
            activeStep === maxSteps - 1 ? (
              <div style={{ width: 60 }}></div>
            ) : (
              <ClickAwayListener onClickAway={() => setProceed(false)}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  style={{
                    background: !proceed ? primaryColor : "#f44336",
                  }}
                  disabled={activeStep === maxSteps - 1}
                >
                  {proceed ? "Go Anyway" : "Next"}
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              </ClickAwayListener>
            )
          }
          backButton={
            <Button
              size="small"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
        <div
          style={{
            position: "fixed",
            background: "white",
            bottom: 10,
            left: "calc(50% - 60px)",
          }}
        >
          <img
            src="https://storage.googleapis.com/rhapsody/logos/rhapsody-concierge.png"
            style={{ height: 40 }}
          />
        </div>
      </div>
    </div>
  );
}
