// import PropTypes from 'prop-types';
// material-ui
import { CircularProgress, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import * as Actions from "actions";
import Layout from "hooks/Layout";
import LayoutBuilder from "hooks/LayoutBuilder";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Musicians({ projectID = 5 }) {
  const [dialOpen, setDialOpen] = React.useState(false);
  const [layoutOpen, setLayoutOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [type, setType] = React.useState();
  const [sections, setSections] = React.useState([]);
  const classes = useStyles(styles);

  const projectSections = useSelector((state) => state.projectSections);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Actions.getProjectStudioSessions(projectID));
  }, []);

  React.useEffect(() => {
    if (!projectSections) {
      dispatch(Actions.getProjectSections(projectID));
    } else {
      setLoading(false);
      setSections(projectSections);
    }
  }, [projectSections]);

  const refreshParojectSections = () => {
    dispatch(Actions.getProjectSections(projectID));
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
        <br />
        <Typography variant="caption" color="textSecondary">
          Loading...
        </Typography>
      </div>
    );
  }

  return (
    <div className="step">
      <SpeedDial
        ariaLabel="SpeedDial example"
        color="secondary"
        icon={<Icon className="far fa-plus" />}
        onOpen={() => setDialOpen(true)}
        onClose={() => setDialOpen(false)}
        open={dialOpen}
        style={{
          position: "fixed",
          bottom: 90,
          right: 20,
        }}
        direction={"up"}
      >
        <SpeedDialAction
          icon={<Icon style={{ fontSize: 18 }} className="fad fa-repeat-alt" />}
          tooltipTitle={"+Alternates"}
          tooltipOpen
          classes={{
            fab: classes.fab,
            staticTooltipLabel: classes.staticTooltip,
          }}
          onClick={() => {
            setType("alternate");
            setLayoutOpen(true);
          }}
        />
        <SpeedDialAction
          icon={<Icon style={{ fontSize: 18 }} className="fad fa-chair" />}
          tooltipTitle={"+Positions"}
          tooltipOpen
          classes={{
            fab: classes.fab,
            staticTooltipLabel: classes.staticTooltip,
          }}
          onClick={() => {
            setType("primary");
            setLayoutOpen(true);
          }}
        />
      </SpeedDial>
      {sections.length === 0 ? (
        <div style={{ textAlign: "center" }}>
          <Typography gutterBottom style={{ paddingTop: "20%" }}>
            Use the <i class="fad fa-plus"></i> button to get started!
            <br />
            <Typography
              style={{ paddingTop: "20%", lineHeight: 0 }}
              color="textSecondary"
              variant="caption"
            >
              Add all people that need to be contacted.
              <br />
              Set also their alternates if needed.{" "}
            </Typography>
          </Typography>
        </div>
      ) : (
        <Layout sections={sections} projectID={projectID} />
      )}
      <LayoutBuilder
        open={layoutOpen}
        onClose={() => setLayoutOpen(false)}
        type={type}
        projectID={projectID}
        onSubmit={refreshParojectSections}
      />
    </div>
  );
}
